import {
  CheckIcon,
  ChevronDownIcon,
  ClockIcon
} from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { Popover } from 'lib/components/Popover';
import { PublicationIssueStatus } from 'lib/types/publicationIssue';
import React, { useState } from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { shouldShowDisabledColumnForUser } from 'lib/pagination/helpers';
import {
  EOrganization,
  ESnapshotExists,
  EUser,
  exists,
  FirebaseTimestamp
} from 'lib/types';
import { isColumnUser } from 'lib/helpers';
import { Product } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import moment from 'moment';
import {
  PublicationIssueStatusLabels,
  getShouldUseAdvancedPagination
} from './paginationTableUtils';

const SIMPLIFIED_AVAILABLE_STATUSES = [
  PublicationIssueStatus.PENDING,
  PublicationIssueStatus.APPROVED,
  PublicationIssueStatus.DISABLED,
  PublicationIssueStatus.ARCHIVED
];

const filterForSimplifiedIssueStatuses = (status: PublicationIssueStatus) =>
  SIMPLIFIED_AVAILABLE_STATUSES.includes(status);

const filterForAdvancedIssueStatuses = (user: ESnapshotExists<EUser>) =>
  shouldShowDisabledColumnForUser(user)
    ? () => true
    : (status: PublicationIssueStatus) =>
        status !== PublicationIssueStatus.DISABLED;

const getFilterStatusesFromProduct = (
  product: Product,
  user: ESnapshotExists<EUser>,
  usingAdvancedPagination: boolean
) => {
  if (usingAdvancedPagination) {
    return filterForAdvancedIssueStatuses(user);
  }

  switch (product) {
    case Product.Obituary:
    case Product.Classified:
      return filterForSimplifiedIssueStatuses;
    default:
      return filterForAdvancedIssueStatuses(user);
  }
};

export const blockUserFromChangingToStatus = ({
  user,
  currentStatus,
  newStatus,
  usingAdvancedPagination,
  deadlineTimestamp,
  product
}: {
  user: ESnapshotExists<EUser>;
  currentStatus: string;
  newStatus: string;
  usingAdvancedPagination: boolean;
  deadlineTimestamp: FirebaseTimestamp | null;
  product: Product;
}) => {
  if (currentStatus === newStatus) {
    return true;
  }

  if (!usingAdvancedPagination) {
    /**
     * TODO(Ari): We currently only offer the "simplified" pagination table for obits and classifieds,
     * so in the normal course of events, we shouldn't see the simplified pagination service being used
     * with notices. However, there is one known edge case where, if a user is shadowing multiple newspapers,
     * some of which use advanced notice pagination and some which do not, and they view the notice pagination
     * table in the "All Organizations" view, they will see all of the papers they shadow in the table, regardless
     * of whether or not they are on the advanced pagination service. This "bug" actually provides useful workflows
     * to Column reps (see https://columnpbc.slack.com/archives/C05UYE88E85/p1732546527984479), so instead of hiding
     * the non-pagination papers in the table, we just block them from being "approved" so that they don't trigger
     * unexpected attempts to send notices to the publisher. This loophole will be remediated when we begin to offer
     * the simplified pagination service to notices.
     */
    const onSimplifiedNoticePagination = product === Product.Notice;
    if (
      currentStatus === PublicationIssueStatus.PENDING &&
      newStatus === PublicationIssueStatus.APPROVED
    ) {
      return onSimplifiedNoticePagination;
    }

    // Column users *not using advanced pagination* can go from pending to archived
    if (
      currentStatus === PublicationIssueStatus.PENDING &&
      newStatus === PublicationIssueStatus.ARCHIVED
    ) {
      return false;
    }

    return true;
  }

  // Column users can change the status of any issue at any time
  if (isColumnUser(user)) {
    // Column users can go from pending to ready for pagination
    if (
      currentStatus === PublicationIssueStatus.PENDING &&
      newStatus === PublicationIssueStatus.READY_FOR_PAGINATION
    ) {
      return false;
    }

    // Column users can go from ready for pagination to awaiting approval
    if (
      currentStatus === PublicationIssueStatus.READY_FOR_PAGINATION &&
      newStatus === PublicationIssueStatus.AWAITING_APPROVAL
    ) {
      return false;
    }

    // Column users can go from approved back to ready for pagination
    if (
      currentStatus === PublicationIssueStatus.APPROVED &&
      newStatus === PublicationIssueStatus.READY_FOR_PAGINATION
    ) {
      return false;
    }

    // Column users can request changes on blocks
    if (
      currentStatus === PublicationIssueStatus.AWAITING_APPROVAL &&
      newStatus === PublicationIssueStatus.CHANGES_REQUESTED
    ) {
      return false;
    }

    // Column users can shift pagination issues back to the reps team
    if (newStatus === PublicationIssueStatus.PENDING) {
      return false;
    }

    if (
      newStatus === PublicationIssueStatus.ARCHIVED &&
      !!deadlineTimestamp &&
      moment().isAfter(moment(deadlineTimestamp.toDate()))
    ) {
      return false;
    }

    // Column users can move anything out of completed statuses for more work
    if (
      currentStatus === PublicationIssueStatus.PRINT_READY ||
      currentStatus === PublicationIssueStatus.DISABLED ||
      currentStatus === PublicationIssueStatus.APPROVED ||
      currentStatus === PublicationIssueStatus.ARCHIVED
    ) {
      return false;
    }
  }

  // liaisons can approve blocks
  if (
    currentStatus === PublicationIssueStatus.AWAITING_APPROVAL &&
    newStatus === PublicationIssueStatus.APPROVED
  ) {
    return false;
  }

  // liaisons can request changes on blocks
  if (
    currentStatus === PublicationIssueStatus.AWAITING_APPROVAL &&
    newStatus === PublicationIssueStatus.CHANGES_REQUESTED
  ) {
    return false;
  }

  // liaisons can mark blocks as print ready
  if (
    currentStatus === PublicationIssueStatus.APPROVED &&
    newStatus === PublicationIssueStatus.PRINT_READY
  ) {
    return false;
  }

  // liaisons can go from approved back to ready for pagination
  if (
    currentStatus === PublicationIssueStatus.APPROVED &&
    newStatus === PublicationIssueStatus.AWAITING_APPROVAL
  ) {
    return false;
  }

  // liaisons can go from print ready back to ready for pagination
  if (
    currentStatus === PublicationIssueStatus.PRINT_READY &&
    newStatus === PublicationIssueStatus.AWAITING_APPROVAL
  ) {
    return false;
  }

  // liaisons can move any block out of disabled at their discretion in case of issue
  if (
    currentStatus === PublicationIssueStatus.DISABLED &&
    newStatus !== PublicationIssueStatus.DISABLED
  ) {
    return false;
  }

  // block all other status changes
  return true;
};

type IssueStatusOptionsProps = {
  id: string;
  currentStatus: string;
  handleStatusChange: (status: PublicationIssueStatus) => Promise<void>;
  publisher: ESnapshotExists<EOrganization>;
  product: Product;
  deadlineTimestamp: FirebaseTimestamp | null;
};

function IssueStatusOptions({
  id,
  currentStatus,
  handleStatusChange,
  publisher,
  product,
  deadlineTimestamp
}: IssueStatusOptionsProps) {
  const { value: usingAdvancedPagination } = useAsyncEffect({
    fetchData: async () => getShouldUseAdvancedPagination(publisher, product),
    dependencies: [publisher.id, product],
    initialData: false
  });
  const user = useAppSelector(selectUser);
  if (!exists(user)) return null;

  const filterStatuses = getFilterStatusesFromProduct(
    product,
    user,
    !!usingAdvancedPagination
  );

  return (
    <div
      className="w-48 space-y-2 p-4"
      onClick={(e: React.MouseEvent) => {
        // Prevent the drawer from opening
        e.stopPropagation();
      }}
    >
      {Object.values(PublicationIssueStatus)
        .filter(filterStatuses)
        .map((statusOption, i) => {
          return (
            <div key={`wrapper-${id}-${i}`} className="w-full flex">
              <ColumnButton
                key={`${id}-${i}`}
                type={'button'}
                link
                secondary={currentStatus !== statusOption}
                tertiary={currentStatus === statusOption}
                buttonText={PublicationIssueStatusLabels[statusOption]}
                disabled={blockUserFromChangingToStatus({
                  user,
                  currentStatus,
                  newStatus: statusOption,
                  usingAdvancedPagination: !!usingAdvancedPagination,
                  deadlineTimestamp,
                  product
                })}
                onClick={() => {
                  void handleStatusChange(statusOption);
                }}
              />
            </div>
          );
        })}
    </div>
  );
}

function getIssueStatusBadgeIcon(status: PublicationIssueStatus) {
  switch (status) {
    case PublicationIssueStatus.APPROVED:
      return <CheckIcon className="w-4 h-4" />;
    default:
      return <ClockIcon className="w-4 h-4" />;
  }
}

const getIssueStatusBadgeStyle = (status: PublicationIssueStatus) => {
  switch (status) {
    case PublicationIssueStatus.APPROVED:
      return 'success';
    case PublicationIssueStatus.AWAITING_APPROVAL:
      return 'warning';
    default:
      return undefined;
  }
};

type IssueStatusBadgeProps = {
  publicationIssueWithSection: PublicationIssueWithSection;
  reloadPublicationIssues: () => void;
  publisher: ESnapshotExists<EOrganization>;
  product: Product;
};

export function IssueStatusBadge({
  publicationIssueWithSection,
  reloadPublicationIssues,
  publisher,
  product
}: IssueStatusBadgeProps) {
  const controllingDocument =
    publicationIssueWithSection.section ??
    publicationIssueWithSection.publicationIssue;
  const user = useAppSelector(selectUser);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    publicationDate
  } = publicationIssueWithSection.publicationIssue.modelData;
  const { status, deadlineTimestamp } = controllingDocument.modelData;

  const handleStatusChange = async (newStatus: PublicationIssueStatus) => {
    if (newStatus === PublicationIssueStatus.APPROVED) {
      setShowConfirmationModal(true);
    } else {
      await updateStatusAndReload(newStatus);
    }
  };

  const handleConfirmApproval = async () => {
    await updateStatusAndReload(PublicationIssueStatus.APPROVED);
    setShowConfirmationModal(false);
  };

  const updateStatusAndReload = async (status: PublicationIssueStatus) => {
    if (!exists(user)) {
      return;
    }
    setLoading(true);
    const [updateError] = await controllingDocument.updateStatus(user, status);
    if (updateError) {
      logAndCaptureException(
        ColumnService.PAGINATION,
        updateError,
        'Error while updating publication issue or section status in pagination table',
        { docPath: controllingDocument.ref.path, newStatus: status }
      );
    }
    void reloadPublicationIssues();
  };

  return (
    <>
      {loading ? (
        <div className="inline-flex">
          <LoadingSpinner size="sm" />
        </div>
      ) : (
        <Popover
          id={`issue-status-popover-${controllingDocument.id}`}
          activator={
            <Badge
              status={getIssueStatusBadgeStyle(status)}
              startIcon={getIssueStatusBadgeIcon(status)}
            >
              <div className="flex space-x-1 cursor-pointer">
                <span>{PublicationIssueStatusLabels[status]}</span>
                <span className="m-auto">
                  <ChevronDownIcon className="w-3 h-3" />
                </span>
              </div>
            </Badge>
          }
        >
          <IssueStatusOptions
            id={`issue-status-${controllingDocument.id}`}
            currentStatus={status}
            handleStatusChange={handleStatusChange}
            publisher={publisher}
            product={product}
            deadlineTimestamp={deadlineTimestamp}
          />
        </Popover>
      )}

      {showConfirmationModal && (
        <CancelOrSubmitModal
          onClose={() => setShowConfirmationModal(false)}
          primaryButtonText={'Submit'}
          tertiaryButtonText="Cancel"
          onSubmit={handleConfirmApproval}
          header={
            <div className="text-xl font-bold">
              Approve {publicationDate} for {publisher.data().name}
            </div>
          }
        >
          <div className="text-l font-bold py-4">
            Are you sure you want to submit this issue for approval?
          </div>
        </CancelOrSubmitModal>
      )}
    </>
  );
}
