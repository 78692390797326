import { EOrganization, ESnapshotExists, EFirebaseContext } from '../types';
import { Product } from '../enums';
import { getInheritedProperty } from '../utils/inheritance';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';
import { ColumnService } from '../services/directory';
import { getErrorReporter } from '../utils/errors';
import { ProductUploadSetting } from '../types/upload';
import { safeAsync, safeGetOrThrow } from '../safeWrappers';
import { getAdvancedPaginationOnForProduct } from '../pagination/helpers';

/**
 * @returns A product upload setting if one is found, undefined if none is found
 * for a paper on simplified pagination, or an error if none is found for a paper
 * on advanced pagination
 */
const getProductUploadSettingsForNewspaper = async (
  newspaper: ESnapshotExists<EOrganization>,
  product: Product
): Promise<ResponseOrError<ProductUploadSetting | undefined>> => {
  const ftpSettings = await getInheritedProperty(
    newspaper.ref,
    'productUploadSettings'
  );
  const relevantUploadSettings = ftpSettings?.filter(setting =>
    setting.products.includes(product)
  );
  const [
    getAdvancedPaginationError,
    usingAdvancedPagination
  ] = await safeAsync(() =>
    getAdvancedPaginationOnForProduct(product, newspaper)
  )();
  if (getAdvancedPaginationError) {
    getErrorReporter().logAndCaptureCriticalError(
      ColumnService.PAGINATION,
      getAdvancedPaginationError,
      'Unable to determine if paper is using advanced or simplified pagination',
      {
        newspaperId: newspaper.id,
        product
      }
    );
    return wrapError(getAdvancedPaginationError);
  }

  const noUploadSettingsFound =
    !relevantUploadSettings || relevantUploadSettings.length === 0;
  if (noUploadSettingsFound && !usingAdvancedPagination) {
    getErrorReporter().logInfo(
      'Newspaper with simplified pagination does not have upload settings configured for product',
      {
        newspaperId: newspaper.id,
        product,
        numberOfSettingsFound: `${(relevantUploadSettings || []).length}`
      }
    );
    return wrapSuccess(undefined);
  }

  if (noUploadSettingsFound && usingAdvancedPagination) {
    const err = new Error(
      'Expected exactly one upload setting for product and newspaper'
    );
    getErrorReporter().logAndCaptureCriticalError(
      ColumnService.PAGINATION,
      err,
      'getProductUploadSettingsForNewspaper failed for paper on advanced pagination! Possibly impacting auto bulk uploads!',
      {
        newspaperId: newspaper.id,
        product,
        numberOfSettingsFound: `${(relevantUploadSettings || []).length}`
      }
    );
    return wrapError(err);
  }

  // This shouldn't happen given the checks above, but the compiler needs it for some reason
  if (!relevantUploadSettings) {
    const err = new Error('No upload settings found for product and newspaper');
    getErrorReporter().logAndCaptureCriticalError(
      ColumnService.PAGINATION,
      err,
      'Reached unexpected code path in getProductUploadSettingsForNewspaper',
      {
        newspaperId: newspaper.id,
        product
      }
    );
    return wrapError(err);
  }

  if (relevantUploadSettings.length > 1) {
    const errorMessage =
      'Found more than one upload setting for product and newspaper on simplified pagination!';
    getErrorReporter().logAndCaptureCriticalError(
      ColumnService.PAGINATION,
      new Error(errorMessage),
      'Cannot get product upload settings for newspaper on simplified pagination',
      {
        newspaperId: newspaper.id,
        product,
        numberOfSettingsFound: `${(relevantUploadSettings || []).length}`
      }
    );
    return wrapError(new Error(errorMessage));
  }

  return wrapSuccess(relevantUploadSettings[0]);
};

/**
 * @returns A product upload setting if one is found, undefined if none is found
 * for a paper on simplified pagination, or an error if none is found for a paper
 * on advanced pagination
 */
export const getUploadDestinationByOrgId = async (
  ctx: EFirebaseContext,
  orgId: string,
  product: Product
): Promise<ResponseOrError<ProductUploadSetting | undefined>> => {
  const newspaperRef = ctx.organizationsRef().doc(orgId);
  const [newspaperError, newspaper] = await safeGetOrThrow<EOrganization>(
    newspaperRef
  );
  if (newspaperError) {
    return wrapError(newspaperError);
  }

  return getProductUploadSettingsForNewspaper(newspaper, product);
};
