import { EOrganization } from './organization';
import { ERef, EUser, FirebaseTimestamp } from '.';
import { LineItem } from './invoices';
import { Product } from '../enums';
import { Customer } from './customer';
import { OrderPricing } from './orderDetail';
import { PublishingMedium } from '../enums/PublishingMedium';
import { DisplayParams } from './notice';

// TODO: This order status will be used as a discriminated type for the order to define props at various stages of the order lifecycle
export enum OrderStatus {
  /**
   * Order is unpaid and/or incomplete
   */
  DRAFT = 'draft',

  /**
   * Order is paid and newspaperOrders are visible to the newspaper
   */
  PENDING = 'pending',

  /**
   * All newspaperOrders have been archived as complete
   */
  COMPLETE = 'complete',

  /**
   * Order has been cancelled (TODO)
   */
  CANCELLED = 'cancelled'
}

// Changes to this type do not affect the database
export type NewspaperOrderPublishingData = {
  newspaperId: string;
  publishingMedium: PublishingMedium;
  lineItems: LineItem[];
  displayParams?: DisplayParams;
  columnInches?: number;
  newspaperTimezone: string;
};

// Changes to this type do not affect the database
export type ConsolidatedOrderPricing = OrderPricing & {
  newspaperOrderPublishingDataGroup: NewspaperOrderPublishingData[];
};

/**
 * Only place immutable data in this type. Any mutable data should be placed in the OrderDetail subcollection
 */
export type OrderInfo = {
  /**
   * This helps us to keep track of which order documents (obituaries, classifieds, newspaperOrders, invoices)
   * are currently active on the order. Non-active versions of these documents represent either draft versions
   * created via the edit flow (status: DRAFT) or previous versions of the documents that have been replaced
   * by edits (status: DELETED).
   */
  activeVersion: number;

  /**
   * The product type of the order (e.g. obituary, classified)
   */
  product: Product;

  status: OrderStatus;
  createdAt: FirebaseTimestamp;

  /**
   * Order originalRef when the order is dupliated from another order. This is used to track the original order.
   */
  originalRef?: ERef<Order>;
};

/** Defines the user and optional organization that is the author of the order */
type AuthorizedUserInfo = {
  /**
   * The user that created the order. Can be either the advertiser or the publisher user.
   */
  user: ERef<EUser>;

  /**
   * The organization of the user that placed the order. Can be an advertiser or publisher organization.
   * Will be `null` if the user is an individual without an organization.
   */
  authorizedOrganization: ERef<EOrganization> | null;
};

type AnonymousUserAccess = {
  /**
   * A code used to authorize access of the anonymous placer to the order and its related ads
   * Used for authentication
   * Hashed using SHA256 re: https://columnpbc.slack.com/archives/C063V00UK6W/p1705620013517879
   */
  accessCode: string;
};

type FuneralHomeAccess = {
  /**
   * A code used to authorize async access of funeral homes to the order and its related ads
   * If the funeral home did not place the order directly
   * Hashed using SHA256 re: https://columnpbc.slack.com/archives/C063V00UK6W/p1705620013517879
   */
  funeralHomeAccessCode?: string;
};

export type AnonymousOrderContactInfo = {
  /**
   * The email address of the person who will be contacted about the order
   * Used for authentication
   */
  contactEmail: string;
  firstName: string;
  lastName: string;
  phone: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: number;
  zip?: string;
  organizationName?: string;
};

export type AdditionalAnonymousOrderInfo = AnonymousOrderContactInfo &
  AnonymousUserAccess &
  FuneralHomeAccess;

export type AnonymousOrderInfo = OrderInfo & AdditionalAnonymousOrderInfo;

export type AdditionalPublisherAsAnonymousOrderInfo = AuthorizedUserInfo &
  AnonymousOrderContactInfo &
  FuneralHomeAccess;

type PublisherAsAnonymousOrderInfo = OrderInfo &
  AdditionalPublisherAsAnonymousOrderInfo;

export type AnonymousOrder = AnonymousOrderInfo | PublisherAsAnonymousOrderInfo;

type AdvertiserCustomerOrderInfo = {
  /** This property records the customer selected by the publisher when searching. */
  advertiserCustomer: ERef<Customer>;
};

export type AdditionalAdvertiserWithOrganizationOrderInfo = AuthorizedUserInfo & {
  /**
   * The order's advertiser user
   */
  advertiser: ERef<EUser>;

  /**
   * The organization (e.g., funeral home, law firm, government) of the order's customer
   * Will be the same as `authorizedOrganization` if an advertiser with an organization placed the order
   */
  advertiserOrganization: ERef<EOrganization>;
};

export type AdvertiserWithOrganizationOrderInfo = OrderInfo &
  AdditionalAdvertiserWithOrganizationOrderInfo;

type AdditionalPublisherAsAdvertiserWithOrganizationOrderInfo = AdditionalAdvertiserWithOrganizationOrderInfo &
  AdvertiserCustomerOrderInfo;

type PublisherAsAdvertiserWithOrganizationOrderInfo = OrderInfo &
  AdditionalPublisherAsAdvertiserWithOrganizationOrderInfo &
  FuneralHomeAccess;

export type AdvertiserWithOrganizationOrder =
  | AdvertiserWithOrganizationOrderInfo
  | PublisherAsAdvertiserWithOrganizationOrderInfo;

export type AdditionalIndividualAdvertiserOrderInfo = AuthorizedUserInfo & {
  /**
   * The order's advertiser user
   */
  advertiser: ERef<EUser>;

  /**
   * Always `null` for individual advertisers
   */
  advertiserOrganization: null;
};

type IndividualAdvertiserOrderInfo = OrderInfo &
  AdditionalIndividualAdvertiserOrderInfo;

type AdditionalPublisherAsIndividualAdvertiserOrderInfo = IndividualAdvertiserOrderInfo &
  AdvertiserCustomerOrderInfo;

type PublisherAsIndividualAdvertiserOrderInfo = OrderInfo &
  AdditionalPublisherAsIndividualAdvertiserOrderInfo;

export type IndividualAdvertiserOrder =
  | IndividualAdvertiserOrderInfo
  | PublisherAsIndividualAdvertiserOrderInfo;

export type AdvertiserOrder =
  | AdvertiserWithOrganizationOrder
  | IndividualAdvertiserOrder;
/**
 * Approx schema is Ad -> Order -> NewspaperOrders
 * NewspaperOrders is a subcollection of orders, so it is not described in this type
 */
export type Order =
  | AnonymousOrder
  | AdvertiserWithOrganizationOrder
  | IndividualAdvertiserOrder;

/** @returns true if the order was placed by or on behalf of an anonymous user (rather than a registered advertiser user). */
export const isAnonymousOrder = (
  order: Partial<Order>
): order is AnonymousOrder => !Object.keys(order).includes('advertiser');

/** @returns true if the order was placed by or on behalf of an anonymous user (rather than a registered advertiser user). */
export const isPublisherAsAdvertiserOrder = (
  order: Partial<Order>
): order is
  | PublisherAsIndividualAdvertiserOrderInfo
  | PublisherAsAdvertiserWithOrganizationOrderInfo =>
  Object.keys(order).includes('advertiserCustomer');

/** @returns true if the order was created by an anonymous user (rather than a registered advertiser user). */
export const isAnonymousUserOrder = (
  order: Order
): order is AnonymousOrderInfo => !Object.keys(order).includes('user');

/** @returns true if the order was placed by a publisher on behalf of an anonymous user (rather than a registered advertiser user). */
export const isPublisherAsAnonymousOrder = (
  order: Partial<Order>
): order is PublisherAsAdvertiserWithOrganizationOrderInfo =>
  isAnonymousOrder(order) && !isAnonymousUserOrder(order);

/** @returns true if the order was placed by or on behalf of an advertiser with an organization (rather than an individual). */
export const isAdvertiserWithOrganizationOrder = (
  order: Partial<Order>
): order is AdvertiserWithOrganizationOrder =>
  Object.keys(order).includes('advertiserOrganization') &&
  Boolean((order as AdvertiserOrder).advertiserOrganization);

/** @returns true if the order was placed by a registered individual advertiser */
export const isIndividualAdvertiserOrder = (
  order: Partial<Order>
): order is IndividualAdvertiserOrder =>
  !isAnonymousOrder(order) &&
  !isAdvertiserWithOrganizationOrder(order) &&
  Boolean((order as IndividualAdvertiserOrder).advertiser);

/* This is only needed because the customer search shows singular customers (rather than customer
  organizations). If the UI changes to show organizations instead of customers, this will no longer
  need special consideration. */
/** @returns true if the order was placed by a publisher on behalf of an advertiser with an organization. */
export const isPublisherAsAdvertiserWithOrganizationOrder = (
  order: Partial<Order>
): order is PublisherAsAdvertiserWithOrganizationOrderInfo =>
  isAdvertiserWithOrganizationOrder(order) &&
  isPublisherAsAdvertiserOrder(order);

/** @returns true if the order was placed by a publisher. */
export const isPublisherOrder = (
  order: Partial<Order>
): order is
  | PublisherAsAdvertiserWithOrganizationOrderInfo
  | PublisherAsAnonymousOrderInfo =>
  isPublisherAsAnonymousOrder(order) ||
  isPublisherAsAdvertiserWithOrganizationOrder(order);

export const isAdvertiserOrder = (
  order: Partial<Order>
): order is AdvertiserOrder =>
  isAdvertiserWithOrganizationOrder(order) ||
  isIndividualAdvertiserOrder(order);

export type OrderCreateRequest = AnonymousOrderContactInfo & {
  product: Product;
};

export type OrderCreateResponse =
  | {
      success: true;
      accessCode: string;
    }
  | {
      success: false;
      error: string;
    };

export type OrderTokenResponse = {
  token: string;
  orderId: string;
};

/**
 * This type is used for the receipt HTML template data
 */
export type OrderReceiptLineItemData = Omit<LineItem, 'amount'> & {
  amount: string;
  background: boolean;
};

/**
 * This type is used for the receipt HTML template data
 */
export type OrderReceiptTemplateData = {
  is_receipt: boolean;
  logo: string;
  payToAddress: string;
  description: string;
  customer_name: string;
  help_info: string;
  payment_method_details: string;
  created: string;
  stripe_invoice_id: string;
  stripe_invoice_number: string;
  currency: string;
  lines: {
    data: OrderReceiptLineItemData[];
  };
  subtotal: string;
  total: string;
  total_discount: string;
  coupons: string;
};
