import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
  RefObject
} from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
  ESnapshotExists,
  EOrganization,
  FirebaseUploadTaskSnapshot,
  exists
} from 'lib/types';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment-timezone';

import Firebase from 'EnoticeFirebase';
import { EPublicNotice } from 'lib/types/publicNotice';
import { State } from 'lib/enums';
import { FileType } from 'lib/types/mime';
import {
  awaitAllPromises,
  getRejected,
  guidGenerator,
  removeUndefinedFields,
  sanitize
} from 'lib/helpers';
import Dropzone from 'react-dropzone';
import { truncateString, logAndCaptureException } from 'utils';
import TailwindModal from 'components/TailwindModal';
import SubmitButton from 'components/SubmitButton';
import api from 'api';
import { getCounties } from 'lib/utils/counties';
import {
  AddImageIcon, // no heroicon equiv
  FilledCircleIcon // no heroicon equiv
} from 'icons';
import {
  ChevronRightIcon,
  InformationCircleIcon,
  TrashIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { getFirebaseContext } from 'utils/firebase';
import { TextAreaField } from 'lib/components/TextAreaField';
import { ColumnService } from 'lib/services/directory';
import SettingsHeader from '../../SettingsHeader';
import SelectDropdown from '../../../placeScroll/SelectDropdown';
import { DEFAULT_SELECTABLE_TYPES } from './Archive';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    chipContainerOuter: {
      maxWidth: 600,
      overflow: 'scroll'
    }
  });

type FTPSettingsProps = {
  availableOrganizations: ESnapshotExists<EOrganization>[];
  activeOrganization: ESnapshotExists<EOrganization>;
  classes: Record<string, string>;
};

export const getNoticeChunks = (text: string, pdfUrl: string | null) => {
  if (pdfUrl) return [text.replace('<![CDATA[', '').replace(']]>', '')];
  const trimmedText = text.startsWith('|') ? text.slice(1) : text;

  const PIPE_PATTERN = /[\n\r]\s{0,2}\|\s{0,2}[\n\r]/g;

  let chunks: string[];
  if (trimmedText.match(PIPE_PATTERN)) {
    chunks = trimmedText.split(PIPE_PATTERN);
  } else {
    chunks = trimmedText.split(
      /((\n-\s{0,1}-\s{0,1}-\n)|–{20,40}|(\n\s{0,1}){3}|mmm\s{0,1}\n)/
    );
  }

  const cleanChunks = chunks.map(c =>
    (c || '')
      .replace(/\n-\s{0,1}-\s{0,1}-\n/g, '')
      .replace(/–/g, '')
      .replace(/\n\|\n/g, '')
      // eslint-disable-next-line no-control-regex
      .replace(/[^\x00-\x7F]/g, '')
      .replace(/mmm/g, '')
      .trim()
  );
  return cleanChunks.filter(Boolean);
};

function FTPSettings({
  availableOrganizations,
  activeOrganization,
  classes
}: FTPSettingsProps) {
  const [publicationDates, setPublicationDates] = useState([new Date()]);
  const [noticeText, setNoticeText] = useState('');
  const [uploadedSnapshots, setUploadedSnapshots] = useState<
    FirebaseUploadTaskSnapshot[]
  >([]);
  const [submitting, setSubmitting] = useState(false);
  const [
    selectedOrganization,
    setSelectedOrganization
  ] = useState<ESnapshotExists<EOrganization> | null>(activeOrganization);
  const [publicationMethod, setPublicationMethod] = useState('');
  const shouldShowPublicationMethod =
    activeOrganization.data()?.state === State.florida.value;
  const [county, setCounty] = useState(activeOrganization.data()?.county || '');
  const [noticeType, setNoticeType] = useState('');
  const [expandOption1, setExpandOption1] = useState(true);
  const [expandOption2, setExpandOption2] = useState(false);
  const [expandOption3, setExpandOption3] = useState(false);
  const [files, setFiles] = useState<File[]>();
  const [
    showSubmissionSuccessfulDialog,
    setShowSubmissionSuccessfulDialog
  ] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();
  const dropzoneRef = useRef() as RefObject<HTMLInputElement>;

  const extractText = async (snapshot: FirebaseUploadTaskSnapshot) => {
    const extension =
      snapshot.ref.fullPath.split('.').pop()?.toLowerCase() ?? '';

    if (extension === FileType.PDF) {
      const url = (await snapshot.ref.getDownloadURL()) as string;
      const res = await api.post('documents/run-ocr', { url });
      return {
        noticeText: res.text,
        pdfUrl: url
      };
    }

    const fileTextResp = await fetch(await snapshot.ref.getDownloadURL());
    const noticeText = await fileTextResp.text();
    return {
      pdfUrl: null,
      noticeText
    };
  };

  const uploadNotice = async (
    fullText: string,
    pdfUrl: string | null,
    publicationDate: Date,
    publicationMethod: string,
    county: string,
    noticeType: string,
    totalRuns: number,
    runNumber: number
  ) => {
    if (!exists(selectedOrganization)) {
      return;
    }

    const formattedDate = moment(publicationDate).format('MM/DD/YYYY');
    const stateString = State.by_value(selectedOrganization.data().state)
      ?.label;

    const noticeChunks = getNoticeChunks(fullText.slice(), pdfUrl);
    await Promise.all(
      noticeChunks.map(async cleanText => {
        const hash = guidGenerator();
        console.log('hash & cleanText:', hash, cleanText);
        const updates: Partial<EPublicNotice> = removeUndefinedFields({
          publishedDate: formattedDate,
          newspaperName: selectedOrganization.data().name,
          noticeType: noticeType || 'Legal Notice',
          pdfUrl: pdfUrl || undefined,
          state: stateString,
          text: cleanText,
          county,
          uploadTime: getFirebaseContext()
            .fieldValue()
            .serverTimestamp() as any,
          uploadedBy: selectedOrganization.ref,
          totalRuns,
          runNumber
        });
        if (shouldShowPublicationMethod && publicationMethod) {
          updates.publicationMethod = publicationMethod;
        }
        return await getFirebaseContext()
          .publicNoticesRef()
          .doc(hash)
          .set(updates);
      })
    );
  };
  const uploadFiles = async (files: File[]) => {
    if (!files) return;
    setLoading(true);
    const snapshots: FirebaseUploadTaskSnapshot[] = [];
    await Promise.all(
      files.map(async file => {
        const uniqueFileNameComponents = file.name.split('.');
        uniqueFileNameComponents.splice(-1, 0, guidGenerator().slice(0, 8));
        const uniqueFileName = uniqueFileNameComponents.join('.');
        const snapshot = await Firebase.storage()
          .ref()
          .child(
            `direct-ftp-upload/${activeOrganization.id}/${sanitize(
              uniqueFileName
            )}`
          )
          .put(file);
        snapshots.push(snapshot);
      })
    );
    setUploadedSnapshots(uploadedSnapshots.concat(snapshots));

    setNoticeText('');
    setLoading(false);
  };
  const submitNotices = async () => {
    setSubmitting(true);

    const uploadResults = await awaitAllPromises(
      publicationDates.map(async (publicationDate, runNumber) => {
        if (uploadedSnapshots.length) {
          await Promise.all(
            uploadedSnapshots.map(async snapshot => {
              const { noticeText, pdfUrl } = await extractText(snapshot);
              await uploadNotice(
                noticeText,
                pdfUrl,
                publicationDate,
                publicationMethod,
                county,
                noticeType,
                publicationDates.length,
                runNumber
              );
            })
          );
        } else {
          await uploadNotice(
            noticeText,
            null,
            publicationDate,
            publicationMethod,
            county,
            noticeType,
            publicationDates.length,
            runNumber
          );
        }
      })
    );

    const failedNotices = getRejected(uploadResults);

    if (failedNotices.length) {
      failedNotices.forEach(err => {
        logAndCaptureException(
          ColumnService.FTP,
          err,
          'Failed to upload notice',
          {
            activeOrgId: activeOrganization.id
          }
        );
      });
    } else {
      setNoticeText('');
      setUploadedSnapshots([]);
      setShowSubmissionSuccessfulDialog(true);
    }

    setSubmitting(false);
  };

  useEffect(() => {
    if (files && files.length) {
      void uploadFiles(files);
    }
  }, [files]);

  let countyLabel = 'County';

  const stateString = State.by_value(selectedOrganization?.data().state)?.label;

  if (stateString === 'Louisiana') {
    countyLabel = 'Parish';
  }

  const renderOptionHeader = (
    headerPosition: 1 | 2 | 3,
    subHeader: string,
    expand: boolean,
    setExpand: Dispatch<SetStateAction<boolean>>
  ) => {
    const rotationStyling = expand ? 'transform rotate-90' : '';

    return (
      <div
        className="flex w-full px-5 py-5 border-b border-gray-300 cursor-pointer select-none"
        onClick={() => setExpand(!expand)}
      >
        <div className="w-full">
          <p className="not-italic font-semibold text-base text-gray-700">
            Option {headerPosition}
          </p>
          <p className="not-italic font-normal text-base text-gray-500">
            {subHeader}
          </p>
        </div>
        <div className="flex w-full justify-end items-center">
          <ChevronRightIcon
            className={`w-4 duration-300 ${rotationStyling} text-gray-750`}
          />
        </div>
      </div>
    );
  };

  const renderFTPFeedOption = () => (
    <>
      <div className="flex w-full px-5 py-5 border-b border-gray-300">
        <p className="not-italic font-semibold text-sm text-gray-700 w-2/6">
          Server Name
        </p>
        <p className="not-italic font-normal text-sm text-black w-4/6">
          upload-files.enotice.io
        </p>
      </div>
      <div className="flex w-full px-5 py-5 border-b border-gray-300">
        <p className="not-italic font-semibold text-sm text-gray-700 w-2/6">
          Port
        </p>
        <p className="not-italic font-normal text-sm text-black w-4/6">21</p>
      </div>
      <div className="flex w-full px-5 py-5 border-b border-gray-300">
        <p className="not-italic font-semibold text-sm text-gray-700 w-2/6">
          User Name
        </p>
        <p className="not-italic font-normal text-sm text-black w-4/6">
          {activeOrganization.data().ftp?.username ?? ''}
        </p>
      </div>
      <div className="flex w-full px-5 py-5 border-b border-gray-300">
        <p className="not-italic font-semibold text-sm text-gray-700 w-2/6">
          Password
        </p>
        <p className="not-italic font-normal text-sm text-black w-4/6">
          {activeOrganization.data().ftp?.initialPassword ?? ''}
        </p>
      </div>
    </>
  );

  const renderDirectUploadOption = () => (
    <>
      <div className="flex w-full px-5 py-5 border-b border-gray-300">
        <p className="not-italic font-semibold text-sm text-gray-700 w-2/6">
          Publication Dates *
        </p>
        <div className="not-italic font-normal text-sm text-black w-4/6">
          <div className="flex flex-col mb-2">
            {publicationDates.map((d, i) => (
              <div key={i} className="flex items-center mb-2 cursor-pointer">
                <div className="box-border rounded border border-gray-300 w-1/3">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      label=""
                      value={d}
                      placeholder="MM/DD/YYYY"
                      className={`${classes.picker} date-picker-publish`}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <svg
                            width="60"
                            height="38"
                            className="border-r border-gray-300 mr-4"
                          >
                            <svg
                              width="42"
                              height="38"
                              viewBox="0 0 42 38"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 2C0 0.89543 0.895431 0 2 0H42V38H2C0.89543 38 0 37.1046 0 36V2Z"
                                fill="#F9FAFB"
                              />
                            </svg>
                            <svg
                              x="11.5"
                              y="11"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.66667 4.83333V1.5V4.83333ZM12.3333 4.83333V1.5V4.83333ZM4.83333 8.16667H13.1667H4.83333ZM3.16667 16.5H14.8333C15.2754 16.5 15.6993 16.3244 16.0118 16.0118C16.3244 15.6993 16.5 15.2754 16.5 14.8333V4.83333C16.5 4.39131 16.3244 3.96738 16.0118 3.65482C15.6993 3.34226 15.2754 3.16667 14.8333 3.16667H3.16667C2.72464 3.16667 2.30072 3.34226 1.98816 3.65482C1.67559 3.96738 1.5 4.39131 1.5 4.83333V14.8333C1.5 15.2754 1.67559 15.6993 1.98816 16.0118C2.30072 16.3244 2.72464 16.5 3.16667 16.5Z"
                                stroke="#4A5568"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </svg>
                        )
                      }}
                      autoOk
                      onChange={newDate =>
                        setPublicationDates(
                          publicationDates.map((date, j) =>
                            i === j ? newDate! : date
                          )
                        )
                      }
                    />
                  </MuiPickersUtilsProvider>
                </div>

                {i !== 0 && (
                  <TrashIcon
                    className="w-5 ml-3 text-gray-750"
                    onClick={() =>
                      setPublicationDates(
                        publicationDates.filter((_, j) => i !== j)
                      )
                    }
                  />
                )}
              </div>
            ))}
            <div
              className="font-medium text-base text-indigo-400 cursor-pointer flex items-baseline ml-2"
              onClick={() =>
                setPublicationDates(publicationDates.concat(new Date()))
              }
            >
              <p className="text-2xl mr-2">+</p> Add More Dates
            </div>
          </div>
        </div>
      </div>
      {availableOrganizations && availableOrganizations.length > 1 && (
        <div className="flex w-full px-5 py-5 border-b border-gray-300">
          <p className="flex items-center not-italic font-semibold text-sm text-gray-700 w-2/6">
            Select Publication
          </p>
          <div className="not-italic font-normal text-sm text-black w-4/6">
            <select
              id="select-publication"
              required
              value={selectedOrganization?.id}
              autoComplete="none"
              onChange={e =>
                setSelectedOrganization(
                  availableOrganizations.find(o => o.id === e.target.value) ??
                    null
                )
              }
              className={`appearance-none form-select ${
                !selectedOrganization && 'text-gray-500'
              } rounded block w-1/2 px-3 py-2 border border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5`}
            >
              <option value="" disabled hidden>
                Select Publication *
              </option>
              {availableOrganizations.map(o => (
                <option key={o.id} value={o.id}>
                  {o.data().name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {activeOrganization &&
        activeOrganization.data().state === State.florida.value && (
          <div className="flex w-full px-5 py-5 border-b border-gray-300">
            <p
              className="not-italic font-semibold text-sm text-gray-700 w-2/6"
              style={{ alignSelf: 'center' }}
            >
              Publication Method *
            </p>
            <div className="not-italic font-normal text-sm text-gray-500 mt-1 w-4/6">
              <div
                className="flex items-center"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingRight: '45%'
                }}
              >
                <div id="print-and-website-block">
                  <input
                    value="print and website"
                    id="print-and-website"
                    name="publication-method"
                    type="radio"
                    className="form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
                    onChange={e =>
                      setPublicationMethod(e.target.value as string)
                    }
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="ml-3" htmlFor="print-and-website">
                    <span className="text-md leading-5 font-medium text-gray-700 opacity-75">
                      Print and website
                    </span>
                  </label>
                </div>
                <div id="website-only-block">
                  <input
                    value="website only"
                    id="website-only"
                    name="publication-method"
                    type="radio"
                    className="form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
                    onChange={e =>
                      setPublicationMethod(e.target.value as string)
                    }
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="ml-3" htmlFor="website-only">
                    <span className="text-md leading-5 font-medium text-gray-700 opacity-75">
                      Website only
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
      <div className="flex w-full px-5 py-5 border-b border-gray-300">
        <p
          className="not-italic font-semibold text-sm text-gray-700 w-2/6"
          style={{ alignSelf: 'center' }}
        >
          {countyLabel} *
        </p>
        <div className="flex flex-col not-italic font-normal text-sm text-black w-3/5">
          <SelectDropdown
            className="not-italic font-normal text-sm text-black"
            id="county"
            onChange={(selected: Record<string, string>) => {
              setCounty(selected.label);
            }}
            placeholder="County *"
            options={getCounties(
              State.by_value(activeOrganization.data().state)?.label
            ).map(county => ({ id: county, label: county }))}
            selected={{ id: county, label: county }}
            value={{ id: county, label: county }}
            borderRadius={4}
            borderColor={'#e2e8f0'}
            placeholderText={'#a0aec0'}
            required
            noShadow
            maxHeight={250}
          />
        </div>
      </div>
      <div className="flex w-full px-5 py-5 border-b border-gray-300">
        <div className="flex flex-col w-2/6">
          <p className="not-italic font-semibold text-sm text-gray-700 w-2/6">
            Notice Type *
          </p>
          <p className="not-italic font-normal text-sm text-gray-500 mt-1 w-3/4">
            Select your notice type.
          </p>
        </div>
        <div className="flex flex-col not-italic font-normal text-sm text-black w-3/5">
          <SelectDropdown
            className="not-italic font-normal text-sm text-black"
            id="noticeType"
            onChange={(selected: Record<string, string>) => {
              setNoticeType(selected.label);
            }}
            placeholder="Select notice type"
            options={DEFAULT_SELECTABLE_TYPES.map(type => ({
              id: type,
              label: type
            }))}
            selected={noticeType ? { id: noticeType, label: noticeType } : ''}
            value={noticeType ? { id: noticeType, label: noticeType } : ''}
            borderRadius={4}
            borderColor={'#e2e8f0'}
            placeholderText={'#a0aec0'}
            required
            noShadow
            maxHeight={250}
          />
        </div>
      </div>
      <div className="flex w-full px-5 py-5">
        <div className="flex flex-col w-2/6">
          <p className="not-italic font-semibold text-sm text-gray-700">
            Notice Content *
          </p>
          <p className="not-italic font-normal text-sm text-gray-500 mt-1 w-3/4">
            Upload a file of your notice, or manually paste the notice content,
            to submit. <b>You may only submit a single notice at a time. </b>
            This is to ensure that each notice can be individually searched and
            tagged with the correct notice type.
          </p>
        </div>
        <div className="flex flex-col not-italic font-normal text-sm text-black w-3/5">
          {loading ? (
            <div className="flex justify-center items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md h-48">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" />
            </div>
          ) : (
            <Dropzone
              onDrop={files => {
                setFiles(files);
              }}
              accept={'.pdf,.txt'}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="w-full"
                  {...getRootProps()}
                  onClick={() => {
                    dropzoneRef && (dropzoneRef as any).current.click();
                  }}
                >
                  <div className="flex justify-center items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md h-48">
                    <div className="flex flex-col items-center text-center">
                      {uploadedSnapshots && uploadedSnapshots.length ? (
                        <FilledCircleIcon className="mb-2 text-blue-150" />
                      ) : (
                        <AddImageIcon className="mx-auto h-12 w-12 text-gray-500" />
                      )}
                      <>
                        <p className="mt-1 mr-1 text-sm text-gray-700">
                          <button
                            type="button"
                            className="mr-1 font-medium focus:outline-none focus:underline transition duration-150 ease-in-out"
                            style={{ color: 'rgb(47, 128, 237)' }}
                          >
                            Upload{' '}
                            {uploadedSnapshots && uploadedSnapshots.length
                              ? 'additional files '
                              : 'a file '}
                          </button>
                          or drag and drop
                        </p>
                        <p className="mt-1 text-xs text-gray-500">
                          TXT or PDF files
                        </p>
                      </>
                      <input
                        id="temp-upload"
                        {...getInputProps()}
                        ref={dropzoneRef}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          )}
          {(!uploadedSnapshots || !uploadedSnapshots.length) && (
            <div className="mt-2">
              <TextAreaField
                id="statesite-notice-content"
                rows={4}
                value={noticeText}
                onChange={value => setNoticeText(value)}
                disabled={!!uploadedSnapshots.length}
                labelText=""
                placeholder={
                  uploadedSnapshots.length
                    ? 'Remove the uploaded files to copy and paste content'
                    : 'Paste your notice content here'
                }
              />
            </div>
          )}
        </div>
      </div>
      {uploadedSnapshots && uploadedSnapshots.length !== 0 && (
        <div className="flex w-full px-5 py-5">
          <div className="flex flex-col w-2/6">
            <p className="not-italic font-semibold text-sm text-gray-700">
              Uploaded Files
            </p>
            <p className="not-italic font-medium text-sm text-gray-500 mt-1 w-3/4">
              Click the "x" button to remove uploaded files
            </p>
          </div>
          <div className="flex flex-wrap items-start not-italic font-normal text-sm text-black w-4/6">
            {uploadedSnapshots.map((snap, i) => (
              <div
                key={i}
                className="flex rounded-lg content-around items-center px-2 py-1 mr-2 mb-2 bg-blue-100 font-semibold text-xs text-blue-500"
              >
                <p className="mr-2">
                  {snap && snap.ref ? truncateString(snap.ref.name) : ''}
                </p>
                <XMarkIcon
                  className="cursor-pointer h-3 w-3 text-blue-500"
                  onClick={() => {
                    setUploadedSnapshots(
                      uploadedSnapshots.filter(
                        s => s.ref.fullPath !== snap.ref.fullPath
                      )
                    );
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex justify-end w-full p-5 rounded bg-gray-100">
        <SubmitButton
          onClick={() => submitNotices()}
          disabled={
            !selectedOrganization ||
            submitting ||
            (!uploadedSnapshots.length && !noticeText) ||
            (shouldShowPublicationMethod && !publicationMethod)
          }
          loading={submitting}
          text="Submit"
          type="button"
        />
      </div>
    </>
  );

  const renderUseIngestionOption = () => (
    <div className="w-full p-5 border-b border-gray-300">
      <p
        className="not-italic font-normal text-sm text-gray-700"
        style={{ alignSelf: 'center' }}
      >
        Use Column to manage your public notices and we'll automatically upload
        published notices to the statewide website. If you would like to discuss
        this option, please use{' '}
        <a
          className="text-blue-700 underline"
          href="https://meetings.hubspot.com/alex-lutz/misc-state-site-uploads"
        >
          this link to schedule a time
        </a>{' '}
        that works for you.
      </p>
    </div>
  );

  return (
    <>
      <div>
        <SettingsHeader
          header="Upload Settings"
          description="Specify which upload method you prefer."
        >
          <ColumnButton
            tertiary
            id="upload-settings"
            size="lg"
            buttonText={'Help'}
            onClick={() =>
              window.open(
                'https://help.column.us/en_us/categories/state-sites-Skb5KqcM2'
              )
            }
            startIcon={
              <InformationCircleIcon className="w-5 h-5 text-gray-750" />
            }
            type="button"
          />
        </SettingsHeader>
      </div>
      {renderOptionHeader(
        1,
        'Upload notices directly',
        expandOption1,
        setExpandOption1
      )}
      {expandOption1 && renderDirectUploadOption()}
      {renderOptionHeader(
        2,
        'Upload via FTP feed',
        expandOption2,
        setExpandOption2
      )}
      {expandOption2 && renderFTPFeedOption()}
      {renderOptionHeader(
        3,
        'Upload notices automatically',
        expandOption3,
        setExpandOption3
      )}
      {expandOption3 && renderUseIngestionOption()}
      {showSubmissionSuccessfulDialog && (
        <TailwindModal
          header="Submission successful."
          close={() => setShowSubmissionSuccessfulDialog(false)}
        >
          <>
            <div className="font-medium text-base text-gray-500 mb-8">
              The submitted notices will be viewable on your site upon
              publication date.
            </div>
            <button
              className={`rounded-md font-semibold bg-blue-100 text-blue-500 text-sm flex items-center px-5 py-2`}
              type="button"
              onClick={() => setShowSubmissionSuccessfulDialog(false)}
            >
              Back to FTP Settings
            </button>
          </>
        </TailwindModal>
      )}
    </>
  );
}

export default withStyles(styles)(FTPSettings);
