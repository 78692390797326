import ColorSwatch from './ColorSwatch';

export const ORDER_PLACEMENT_COLORS = [
  'Black',
  '#1c37ff',
  '#fa1000',
  '#f7a01e',
  '#2c9e00',
  '#f3ff3a',
  '#fbb9e9',
  '#5300ce',
  '#98ffe6',
  'White'
];

type ColorSwatchPanelProps = {
  id: string;
  value: string;
  onChange: (newValue: string) => void;
};

function ColorSwatchPanel({ id, value, onChange }: ColorSwatchPanelProps) {
  return (
    <div className="flex flex-wrap bg-white rounded-md overflow-hidden w-40">
      {ORDER_PLACEMENT_COLORS.map(color => (
        <div key={color} className="flex col-span-1 justify-center">
          <ColorSwatch
            id={id}
            color={color}
            checked={value === color}
            onClick={() => {
              onChange(color);
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default ColorSwatchPanel;
