import moment from 'moment';
import { SnapshotModel, getModelFromSnapshot } from '..';
import { Collections } from '../../constants';
import { InvoiceStatus } from '../../enums';
import {
  BulkInvoice,
  Invoice,
  OrderInvoice,
  PublicNoticeInvoice,
  isBulkInvoiceData,
  isOrderInvoiceData,
  isPublicNoticeInvoiceData
} from '../../types/invoices';
import { UserNoticeModel } from './userNoticeModel';
import {
  ResponseOrColumnError,
  ResponseOrError,
  wrapError,
  wrapSuccess
} from '../../types/responses';
import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../../services/directory';
import { OrderModel } from './orderModel';
import {
  BadRequestError,
  NotFoundError,
  wrapErrorAsColumnError
} from '../../errors/ColumnErrors';
import { safeGetOrThrow } from '../../safeWrappers';
import { isPaidDirectToPublisher } from '../../utils/invoices';

export const INVOICE_STATUSES_UNPAID = [
  InvoiceStatus.unpaid.value,
  InvoiceStatus.payment_failed.value,
  InvoiceStatus.draft.value
];

/**
 * We lump partial refund in here because we currently treat the invoice as "good as paid" in
 * integrations. That status is essentially used to track when the invoice can hold no more
 * refunds because before invoice transactions that was the limitation. Reassess this status
 * when we support multiple partial refunds.
 */
export const INVOICE_STATUSES_FUNDS_RECEIVED = [
  InvoiceStatus.paid.value,
  InvoiceStatus.partially_refunded.value
];

export const INVOICE_STATUSES_FUNDS_PENDING = [
  InvoiceStatus.initiated.value,
  InvoiceStatus.authorized.value
];

export const INVOICE_STATUSES_PAYMENT_OR_PARTIAL_REFUND = [
  ...INVOICE_STATUSES_FUNDS_RECEIVED,
  ...INVOICE_STATUSES_FUNDS_PENDING
];

/**
 * Finalized invoices are non-draft invoices in Stripe that are no longer mutable
 * This concept is used in bulk invoices and some integrations to guard a sync
 */
export const INVOICE_STATUSES_FINALIZED = [
  ...INVOICE_STATUSES_FUNDS_RECEIVED,
  InvoiceStatus.refunded.value
];

/**
 * This model supports a generic type T that extends Invoice, but defaults to PublicNoticeInvoice
 * if no type is provided. This allows us to override cases where this is a different invoice type.
 * Once the discriminated type 'Invoice' is fully implemented, we will no longer need a default here.
 */
export class InvoiceModel<
  T extends Invoice = PublicNoticeInvoice
> extends SnapshotModel<T, typeof Collections.invoices> {
  get type() {
    return Collections.invoices;
  }

  public async getNotice(): Promise<ResponseOrError<UserNoticeModel>> {
    if (!this.isPublicNoticeInvoice()) {
      const err = new Error('Invoice is not a public notice invoice');
      getErrorReporter().logAndCaptureError(
        ColumnService.OBITS,
        err,
        'Cannot get notice for a non-public notice invoice',
        {
          invoiceId: this.id
        }
      );
      return wrapError(err);
    }
    const { response: notice, error: noticeError } = await safeGetOrThrow(
      this.ctx.userNoticesRef().doc(this.modelData.noticeId)
    );
    if (noticeError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.DATABASE,
        noticeError,
        'Unable to get notice for invoice',
        {
          noticeId: this.modelData.noticeId,
          invoiceId: this.id
        }
      );
      return wrapError(noticeError);
    }
    return wrapSuccess(getModelFromSnapshot(UserNoticeModel, this.ctx, notice));
  }

  public async getOrder(): Promise<ResponseOrColumnError<OrderModel>> {
    if (!this.isOrderInvoice()) {
      const err = new Error('Invoice is not an order invoice');
      getErrorReporter().logAndCaptureError(
        ColumnService.OBITS,
        err,
        'Cannot get order for a non-order invoice',
        {
          invoiceId: this.id
        }
      );
      return wrapErrorAsColumnError(err, BadRequestError);
    }
    const { response: orderSnap, error: getOrderError } = await safeGetOrThrow(
      this.modelData.order
    );
    if (getOrderError || !orderSnap) {
      getErrorReporter().logAndCaptureError(
        ColumnService.OBITS,
        getOrderError,
        'Unable to get order for invoice',
        {
          orderId: this.modelData.order.id,
          invoiceId: this.id
        }
      );
      return wrapErrorAsColumnError(getOrderError, NotFoundError);
    }

    return wrapSuccess(getModelFromSnapshot(OrderModel, this.ctx, orderSnap));
  }

  public isFreeInvoice() {
    return this.modelData.pricing.totalInCents === 0;
  }

  public isOrderInvoice(): this is InvoiceModel<OrderInvoice> {
    return isOrderInvoiceData(this.modelData);
  }

  public isPublicNoticeInvoice(): this is InvoiceModel<PublicNoticeInvoice> {
    return isPublicNoticeInvoiceData(this.modelData);
  }

  public isBulkInvoice(): this is InvoiceModel<BulkInvoice> {
    return isBulkInvoiceData(this.modelData);
  }

  public isPastDue() {
    const { due_date } = this.modelData;
    const currentDate = moment();
    const dueDate = moment(due_date * 1000);
    return moment(dueDate).isBefore(currentDate);
  }

  public isUnpaid() {
    return (
      INVOICE_STATUSES_UNPAID.includes(this.modelData.status) &&
      !this.modelData.void
    );
  }

  public isRefunded() {
    return this.modelData.status === InvoiceStatus.refunded.value;
  }

  public isVoided() {
    return !!this.modelData.void;
  }

  public getStatusLabel() {
    return InvoiceStatus.by_value(this.modelData.status)?.label ?? 'Unknown';
  }

  public paymentReceived() {
    return (
      INVOICE_STATUSES_FUNDS_RECEIVED.includes(this.modelData.status) &&
      !this.modelData.void
    );
  }

  public paymentPending() {
    return (
      INVOICE_STATUSES_FUNDS_PENDING.includes(this.modelData.status) &&
      !this.modelData.void
    );
  }

  public paymentMethodMissing() {
    return (
      !this.modelData.paymentMethod ||
      this.modelData.paymentMethod === 'unknown'
    );
  }

  /**
   * The publisher has taken a cash or check payment from the customer. The payment is processed as paid out of platform in Stripe and not in Payway or Elavon.
   */
  public isPaidDirectToPubisher(): boolean {
    return isPaidDirectToPublisher(this);
  }
}
