import CheckboxGroup from 'lib/components/Checkbox/CheckboxGroup';
import { CategoryChoiceOption } from 'lib/types/ad';
import { FilingTypeOption } from './useAvailableFilingTypes';

type CategoryChoiceFormProps = {
  onCategoryChange: (category: CategoryChoiceOption) => void;
  category: CategoryChoiceOption | undefined;
  options: FilingTypeOption[];
};

function CategoryChoiceForm({
  onCategoryChange,
  category,
  options
}: CategoryChoiceFormProps) {
  return (
    <CheckboxGroup
      id="select-category"
      labelText="You can always come back to this step later and change your selection."
      value={options.map(option => ({
        id: option.id,
        labelText: option.title,
        labelDescription: option.description,
        checked: category === option.id,
        prefix: option.icon
      }))}
      onChange={(_newValues, { id: newCategory }) =>
        onCategoryChange(newCategory)
      }
      required
    />
  );
}

export default CategoryChoiceForm;
