import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { EOrganization, ERef, ESnapshotExists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import {
  selectAvailableOrganizations,
  selectIsPublisher,
  selectOrgContextRef
} from 'redux/auth';
import { getModelFromRef } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { searchPublisherOrganizations } from 'routes/placeScroll/helpers';
import { Product } from 'lib/enums';
import { safeGetOrThrow } from 'lib/safeWrappers';
import { getLogger } from 'utils/logger';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { NewspapersContext } from '../NewspapersContext';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';
import {
  getFilingTypeMapForPublisherAndProduct,
  PublisherProductFilingTypeModelMap
} from './filingTypeMaps';

type NewspapersContextProviderProps = {
  children: React.ReactNode;
  newspaperOrdersFormData: NewspaperOrdersFormData;
  product: Product;
};

/**
 * Always cache up to 10 publishers that we could pull via filters in case they are added
 * in the placement flow
 */
const MAX_CACHED_PUBLISHERS = 10;

/**
 * It's critical that we provide all publishers relevant to the newspaper order
 * in context to child components. In fact we currently deem it SO CRITICAL
 * TO DO THAT that we forcibly re-render all children whenever there is a new
 * publisher to worry about. In order to avoid re-rendering and full refreshes,
 * we prime the cache of available publishers with:
 * 1) The current publisher (if the user is a publishing user)
 * 2) The publisher associated with the subdomain
 * 3) Child publishers or allowed organizations
 * 4) The first MAX_CACHED_PUBLISHERS that would show up in search
 * TODO: Figure out if we actually need to be this aggressive!
 */
export function NewspapersContextProvider({
  children,
  newspaperOrdersFormData,
  product
}: NewspapersContextProviderProps) {
  const [
    publishersAvailableForPlacement,
    setPublishersAvailableForPlacement
  ] = useState<ESnapshotExists<EOrganization>[]>([]);
  const [
    filingTypeByPublisherAndPublishingMedium,
    setFilingTypeByPublisherAndPublishingMedium
  ] = useState<PublisherProductFilingTypeModelMap>({});
  const availableOrganizations = useAppSelector(selectAvailableOrganizations);
  const subdomainContext = useAppSelector(selectOrgContextRef);
  const isPublisher = useAppSelector(selectIsPublisher);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Makes sure that all publishers passed into this function are included in the availablePublishers
   */
  const ensurePublisherRefsAvailable = async (
    publisherRefs: ERef<EOrganization>[],
    { blockRenderingWhileAdding }: { blockRenderingWhileAdding: boolean }
  ) => {
    if (!publisherRefs) return;
    const missingPublisherRefs = publisherRefs.filter(
      o => !publishersAvailableForPlacement.find(p => p.id === o.id)
    );
    if (missingPublisherRefs.length === 0) return;
    if (blockRenderingWhileAdding) {
      setIsLoading(true);
    }
    const missingPublisherResponses = await Promise.all(
      missingPublisherRefs.map(ref => safeGetOrThrow(ref))
    );
    const missingPublishers: ESnapshotExists<EOrganization>[] = [];
    for (const { error, response: publisher } of missingPublisherResponses) {
      if (error) {
        getLogger().warn(
          'Unable to find publisher in ensure publishers are available',
          {
            failedids: publisherRefs?.map(o => o.id)
          }
        );
        continue;
      }
      missingPublishers.push(publisher);
    }

    if (getBooleanFlag(LaunchDarklyFlags.ENABLE_FAST_PUBLISHER_SELECTION)) {
      const publisherFilingTypeMapResponses = await Promise.all(
        missingPublishers.map(p =>
          getFilingTypeMapForPublisherAndProduct(p, product, isPublisher)
        )
      );
      const publisherFilingTypeMaps: PublisherProductFilingTypeModelMap = {};
      for (const {
        response: filingTypeMapResponse,
        error
      } of publisherFilingTypeMapResponses) {
        if (error) {
          getLogger().warn('Error getting filing type map for publisher', {
            error
          });
          continue;
        }
        const [publisher, publisherFilingTypeMap] = filingTypeMapResponse;
        publisherFilingTypeMaps[publisher.id] = publisherFilingTypeMap;
      }
      setFilingTypeByPublisherAndPublishingMedium(prevState => ({
        ...prevState,
        ...publisherFilingTypeMaps
      }));
    }

    // use functional setState to ensure that we don't run into any errors with
    // race conditions on multiple updates firing simultaneously
    setPublishersAvailableForPlacement(prevState => [
      ...prevState,
      ...missingPublishers
    ]);
    if (blockRenderingWhileAdding) {
      setIsLoading(false);
    }
  };

  /**
   * Add publishers to the available list whenever we add a new publisher in newspaperOrdersFormData
   */
  const relevantPublisherIds = newspaperOrdersFormData
    .map(o => o.newspaper?.id)
    .concat(publishersAvailableForPlacement.map(o => o.id));
  const uniqueRelevantPublisherIds = [...new Set(relevantPublisherIds)].filter(
    Boolean
  );

  useAsyncEffect({
    fetchData: async () => {
      const uniquePublishers = newspaperOrdersFormData
        .map(o => o.newspaper)
        .filter(Boolean) as ERef<EOrganization>[];
      await ensurePublisherRefsAvailable(uniquePublishers, {
        blockRenderingWhileAdding: true
      });
    },
    // run whenever a new unique publisher is added
    dependencies: [uniqueRelevantPublisherIds.join('')]
  });

  /**
   * Pre-load:
   * 1) organizations in the availableOrganizations array for a particular publisher
   * 2) organizations associated with the subdomain
   * 3) the first MAX_CACHED_PUBLISHERS that show up in search
   */
  useEffect(() => {
    void ensurePublisherRefsAvailable(
      availableOrganizations && availableOrganizations.map(o => o.ref),
      { blockRenderingWhileAdding: false }
    );
  }, [availableOrganizations?.map(o => o.id).join(',')]);
  useEffect(() => {
    if (subdomainContext) {
      void (async () => {
        await ensurePublisherRefsAvailable([subdomainContext], {
          blockRenderingWhileAdding: false
        });
        const subdomainContextModel = await getModelFromRef(
          OrganizationModel,
          getFirebaseContext(),
          subdomainContext
        );
        const {
          response: relatedOrgs
        } = await subdomainContextModel.getRelatedOrganizations();
        if (relatedOrgs) {
          await ensurePublisherRefsAvailable(
            relatedOrgs.map(o => o.ref),
            { blockRenderingWhileAdding: false }
          );
        }
      })();
    }
  }, [subdomainContext?.id]);
  useEffect(() => {
    void (async () => {
      const { publisherOrganizations } = await searchPublisherOrganizations({
        isUserPublisher: isPublisher,
        product
      });
      const publisherOrganizationRefs = publisherOrganizations
        .map(org => getFirebaseContext().organizationsRef().doc(org.id))
        .slice(0, MAX_CACHED_PUBLISHERS);
      await ensurePublisherRefsAvailable(publisherOrganizationRefs, {
        blockRenderingWhileAdding: false
      });
    })();
  }, []);

  const contextMissingPublisherInOrderData = !!newspaperOrdersFormData.find(
    o =>
      !publishersAvailableForPlacement.find(
        publisher => o.newspaper?.id === publisher.id
      )
  );

  const contextValue = useMemo(
    () => ({
      filingTypeByPublisherAndPublishingMedium,
      publishersAvailableForPlacement
    }),
    [publishersAvailableForPlacement.map(p => p.id).join(',')]
  );

  if (isLoading || contextMissingPublisherInOrderData) {
    return <LoadingState />;
  }

  return (
    <NewspapersContext.Provider value={contextValue}>
      {children}
    </NewspapersContext.Provider>
  );
}
