import React, { useState } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import ToastActions, { ToastState } from 'redux/toast';
import { Form } from 'lib/components/Form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { NoteBaseContent, NoteTopics, exists } from 'lib/types';
import { NoteServiceErrorCodes } from 'lib/services/NoteService';
import { createNoteFromClient } from 'utils/notes';
import { TextAreaField } from 'lib/components/TextAreaField';
import { NoteAttachmentField } from './NoteAttachmentField';

type NotesEditorProps = {
  noteTopic: NoteTopics;
};

export default function NotesEditor({ noteTopic }: NotesEditorProps) {
  const dispatch = useAppDispatch();
  const [noteContent, setNoteContent] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [notesAttachment, setNotesAttachment] = useState<File | null>(null);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const user = useAppSelector(selectUser);

  const addNote = async () => {
    if (!exists(user)) return;

    setSubmitting(true);

    const noteBaseContent: NoteBaseContent = {
      noteCreatorOrganization: activeOrganization?.ref || null,
      noteCreator: user.ref,
      content: noteContent
    };

    const result = await createNoteFromClient({
      noteTopic,
      noteContent: noteBaseContent,
      notesAttachment
    });

    if (result.errorCode) {
      dispatch(ToastActions.toastError(errorMap[result.errorCode]));
    }

    setNotesAttachment(null);
    setNoteContent('');
    setSubmitting(false);
  };

  return (
    <Form id="create-note-form" onSubmit={addNote}>
      <div className="border border-column-gray-100 rounded-md pt-4 px-4 pb-2">
        <TextAreaField
          id="comment"
          maxLength={400}
          labelText=""
          rows={1}
          placeholder="Leave a note..."
          value={noteContent}
          onChange={newValue => setNoteContent(newValue)}
          disableResizing
          inline
        />
        <div className="flex justify-between py-2">
          <NoteAttachmentField
            notesAttachment={notesAttachment}
            setNotesAttachment={setNotesAttachment}
          />
          <ColumnButton
            primary
            endIcon={<PaperAirplaneIcon className="h-5 w-5" />}
            buttonText="Submit"
            type="submit"
            size="sm"
            disabled={submitting || !noteContent}
          />
        </div>
      </div>
    </Form>
  );
}

const errorMap: Record<
  NoteServiceErrorCodes,
  Pick<ToastState, 'headerText' | 'bodyText'>
> = {
  NOTE_CREATION_FAILED: {
    headerText: 'Error creating note',
    bodyText: 'Please try refreshing your page and re-submitting your note.'
  },
  NOTE_ATTACHMENT_UPLOAD_FAILED: {
    headerText: 'Error uploading attachment',
    bodyText:
      'Please check the file size you are uploading and your internet connection.'
  }
};
