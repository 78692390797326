import { Layout } from 'lib/types/layout';

/** returns the first layout with image if available, otherwise the first. */
export const getDefaultLayout = (sortedSupportedLayouts: Layout[]) => {
  return sortedSupportedLayouts[
    Math.max(
      sortedSupportedLayouts.findIndex(layout => layout.photos > 0),
      0
    )
  ];
};
