import { useEffect, useMemo, useState } from 'react';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { TabOption } from 'lib/components/Tabs';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectActiveOrganization,
  selectIsPublisher,
  selectShowAllOrgsNotices,
  selectUser
} from 'redux/auth';
import { Alert } from 'lib/components/Alert';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { apiPost } from 'api/typed';
import { debounce } from 'lodash';
import { safeStringify } from 'lib/utils/stringify';
import { ColumnButton } from 'lib/components/ColumnButton';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';
import { SORT_ASCENDING, SORT_DESCENDING } from 'lib/types/searchable';
import { ColumnService } from 'lib/services/directory';
import { Product } from 'lib/enums';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import AdsTable from './AdsTable';
import {
  CANCELLED_ORDERS_TAB,
  COMPLETED_ORDERS_TAB,
  DRAFT_ORDERS_TAB,
  IN_PROGRESS_ORDERS_TAB,
  ORDER_STATUS_TABS
} from './tabs';
import { DEFAULT_ORDER_FILTER, getOrderFilters } from './filters/helpers';
import { AdsTableColumns } from './types';

export interface ObitsTableRow {
  deceasedName: string;
  publicationDates: string;
  orderStatus: string;
  customer: string;
  funeralHomeName: string;
  verification: string;
}

const OBIT_TABLE_PUBLISHER_COLUMNS = [
  AdsTableColumns.DECEASED_NAME,
  AdsTableColumns.AD_DEADLINE,
  AdsTableColumns.CUSTOMER_NAME,
  AdsTableColumns.CATEGORY,
  AdsTableColumns.PUBLICATION_DATE,
  AdsTableColumns.CUSTOMER_TYPE,
  AdsTableColumns.VERIFICATION,
  AdsTableColumns.STATUS
];

const OBIT_TABLE_PUBLISHER_COLUMNS_WITH_NEWSPAPER_NAME = [
  ...OBIT_TABLE_PUBLISHER_COLUMNS.slice(0, 3),
  AdsTableColumns.NEWSPAPER_NAME,
  ...OBIT_TABLE_PUBLISHER_COLUMNS.slice(3)
];

const OBIT_TABLE_FUNERAL_HOME_COLUMNS = [
  AdsTableColumns.DECEASED_NAME,
  AdsTableColumns.AD_DEADLINE,
  AdsTableColumns.PUBLICATION,
  AdsTableColumns.CATEGORY,
  AdsTableColumns.PUBLICATION_DATE,
  AdsTableColumns.STATUS
];

const getTableColumns = (
  isPublisher: boolean,
  tableTab: TabOption,
  showAllOrgsNotices = false
) => {
  const defaultObitsTablePublisherColumns = showAllOrgsNotices
    ? OBIT_TABLE_PUBLISHER_COLUMNS_WITH_NEWSPAPER_NAME
    : OBIT_TABLE_PUBLISHER_COLUMNS;
  const defaultColumns = isPublisher
    ? defaultObitsTablePublisherColumns
    : OBIT_TABLE_FUNERAL_HOME_COLUMNS;
  const shouldShowActionColumn = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_DUPLICATE_ORDERS
  );
  const defaultColumnsWithActions = [
    ...defaultColumns,
    AdsTableColumns.ACTIONS
  ];

  switch (tableTab.id) {
    case IN_PROGRESS_ORDERS_TAB.id:
    case COMPLETED_ORDERS_TAB.id:
      return shouldShowActionColumn
        ? defaultColumnsWithActions
        : defaultColumns;
    case DRAFT_ORDERS_TAB.id:
      return defaultColumns.filter(tab => tab !== AdsTableColumns.STATUS);
    case CANCELLED_ORDERS_TAB.id:
      return (shouldShowActionColumn
        ? defaultColumnsWithActions
        : defaultColumns
      ).filter(tab => tab !== AdsTableColumns.STATUS);
    default:
      logAndCaptureMessage('Unexpected tab selected in obits table', {
        tableTab: tableTab.id
      });
      return defaultColumns;
  }
};

function ObitsTableContainer() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const isPublisher = useAppSelector(selectIsPublisher);
  const showAllOrgsNotices = useAppSelector(selectShowAllOrgsNotices);
  const user = useAppSelector(selectUser);
  const [selectedAdTableTab, setSelectedAdTableTab] = useState(
    ORDER_STATUS_TABS[0]
  );
  const [userError, setUserError] = useState<string>();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [rowFilter, setRowFilter] = useState(DEFAULT_ORDER_FILTER);
  const dispatch = useAppDispatch();
  const allowedOrganizations = user?.data().allowedOrganizations;

  const loadObituaries = async () => {
    setUserError(undefined);
    if (!activeOrganization || !selectedAdTableTab || !user) {
      return [];
    }
    const filters = getOrderFilters({
      product: Product.Obituary,
      activeOrganization,
      user,
      selectedAdTableTab,
      tableFilters: rowFilter,
      isPublisher,
      allowedOrganizations,
      showAllOrgsNotices
    });

    const result = await apiPost('search/orders', {
      search: debouncedSearchTerm,
      filters,
      sort: [
        {
          addeadline:
            selectedAdTableTab.id === IN_PROGRESS_ORDERS_TAB.id
              ? SORT_ASCENDING
              : SORT_DESCENDING
        }
      ]
    });

    if (!result.success) {
      logAndCaptureException(
        ColumnService.ORDER_MANAGEMENT,
        result.error,
        'Error loading obituaries table',
        {
          activeOrganization: activeOrganization.id
        }
      );
      setUserError('There was an error loading the data. Please try again.');
      return;
    }

    return result.results;
  };

  const {
    isLoading,
    value: obitTableData,
    invalidateData: refreshTableData
  } = useAsyncEffect({
    fetchData: loadObituaries,
    dependencies: [
      !!activeOrganization,
      !!user,
      isPublisher,
      selectedAdTableTab?.id,
      debouncedSearchTerm,
      safeStringify(rowFilter)
    ]
  });

  const updateSearch = useMemo(
    () =>
      debounce(value => {
        setDebouncedSearchTerm(value);
      }, 400),
    []
  );

  useEffect(() => {
    updateSearch(searchTerm);
  }, [searchTerm]);

  return (
    <>
      {userError && (
        <Alert id="obituary-table-error" status="error" title={userError} />
      )}
      <AdsTable
        product={Product.Obituary}
        ads={obitTableData ?? []}
        tabs={ORDER_STATUS_TABS}
        columns={getTableColumns(
          isPublisher,
          selectedAdTableTab,
          showAllOrgsNotices
        )}
        header={{
          title: {
            [IN_PROGRESS_ORDERS_TAB.id]: 'In progress obituaries',
            [COMPLETED_ORDERS_TAB.id]: 'Completed obituaries',
            [DRAFT_ORDERS_TAB.id]: 'Drafted obituaries',
            [CANCELLED_ORDERS_TAB.id]: 'Cancelled obituaries'
          }[selectedAdTableTab.id],
          subtitle: {
            [IN_PROGRESS_ORDERS_TAB.id]:
              "Obituaries that haven't completed their final run date.",
            [COMPLETED_ORDERS_TAB.id]:
              'Obituaries that have passed their final run date.',
            [DRAFT_ORDERS_TAB.id]:
              'Obituaries that were not fully submitted but can still be completed.',
            [CANCELLED_ORDERS_TAB.id]:
              'Obituaries that were cancelled either by your organization or the client.'
          }[selectedAdTableTab.id],
          additionalContent: (
            <ColumnButton
              type="button"
              buttonText="Place an obituary"
              startIcon={<PlusCircleIcon className="w-5 h-5" />}
              onClick={() => {
                dispatch(push('/obituaries/place'));
              }}
              primary
            />
          )
        }}
        selectedAdTableTab={selectedAdTableTab}
        setSelectedAdTableTab={setSelectedAdTableTab}
        loading={isLoading}
        activeOrganization={activeOrganization}
        isPublisher={isPublisher}
        setSearchTerm={setSearchTerm}
        rowFilter={rowFilter}
        setRowFilter={setRowFilter}
        refreshTableData={refreshTableData}
      />
    </>
  );
}

export default ObitsTableContainer;
