import React, {
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { logAndCaptureException } from 'utils';
import { createStyles, withStyles } from '@material-ui/core/styles';
import useWindowResize from 'utils/UseWindowResize';
import { NoticeType, Product, SyncStatusCategory } from 'lib/enums';
import { cdnIfy, sanitize } from 'lib/helpers';
import {
  ENoticeDraft,
  ENoticeFileNoticeContent,
  EOrganization,
  EResponseTypes,
  ESnapshotExists,
  ETemplateStyles,
  exists
} from 'lib/types';
import { sanitizeNoticeContentHtml } from 'components/noticePreview/mceHelpers';
import { validateHtml } from 'components/noticePreview/indesign_client_utils';
import { connect } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import api from 'api';
import PlacementActions, {
  EPlacement,
  placementSelector,
  selectConfirmedHtml,
  selectIsCustomNoticeType,
  selectIsDisplayNoticeType,
  selectIsEditing,
  syncDynamicHeadersChange
} from 'redux/placement';
import NoticeEditorMadlib from 'routes/madlib/components/NoticeEditorMadlib';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { isNoticeContentData, NoticeFileTypes } from 'lib/types/notice';
import { getUploadedFileData } from 'utils/duplicateNotice';
import { FileWithUploadRef } from 'lib/frontend/hooks/useFirebaseStorageUpload';
import { getLocationParams } from 'lib/frontend/utils/browser';
import {
  noticeTypeSupportsImagesInLiners,
  rateSupportsImagesInLiners,
  shouldRemoveImagesFromLiners
} from 'lib/utils/images';
import { getFileTypeFromFile } from 'lib/files';
import * as affinityXLibHelpers from 'lib/integrations/affinityx/helpers';
import { FileType, getVerifiedExtensionFromFileName } from 'lib/types/mime';
import { selectIsPublisher } from 'redux/auth';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { PlacementError } from 'lib/errors/PlacementError';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { getFirebaseContext } from 'utils/firebase';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import {
  MANUAL_BUILD_AD_REQUEST_SYNC,
  ManualBuildAdRequestEvent,
  SyncEvent
} from 'lib/types/events';
import { wordOrPDFToHtml } from 'utils/word';
import { csvToHtml } from 'utils/csv';
import { clearDataForEditorOverride } from 'lib/utils/madlib';
import { syncStatusMatchesCriteria } from 'lib/utils/events';
import { updateDraftFiles } from 'redux/placement/placementActions';
import { ColumnService } from 'lib/services/directory';
import {
  selectCurrentlySelectedNoticeType,
  selectHasNoticeTypeSelect,
  selectHasStateLevelNoticeTypes,
  selectNoticeTitleFieldConfig,
  selectShouldLoadMadlibForNoticeType,
  selectShouldLoadTypeformForNoticeType,
  selectShouldShowNoticeTypeFields,
  selectShowHeightWarning
} from '../placeScrollSelectors';
import { NoticeContentInner } from './NoticeContentInner';
import { UploadButton } from './UploadButton';
import { NoticeContentAlerts } from './Alerts';

import ScrollStep from '../ScrollStep';
import Firebase from '../../../EnoticeFirebase';
import {
  FileChanges,
  FileWithProperties,
  getFileChanges
} from '../helpers/fileChanges';

import { SelectNoticeType } from '../SelectNoticeType';
import { NoticeContentStepTypeformEmbed } from '../NoticeContentStepTypeformEmbed';

import { SelectContentPrepared } from '../SelectContentPrepared';
import { getAdTypeRecommendationFromFileWithUploadRef } from './adTypeRecommendation';
import { AdTypeRecommendation } from './adTypeRecommendation/getAdTypeRecommendationFromProperties';
import MadlibOverride from './MadlibOverride';
import MadlibOverrideAlert from './MadlibOverrideAlert';

const styles = (theme: any) =>
  createStyles({
    convertContentTypeButton: {
      marginTop: theme.spacing(3), // mt-6
      width: 220,
      height: 55,
      color: '#2C9BDB',
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: 'Helvetica Neue',
      textTransform: 'none',
      border: 'solid',
      borderWidth: 'thin',
      borderColor: '#2C9BDB'
    }
  });

function getStepCaption({
  hasNoticeTypeFields,
  isMadlib
}: {
  hasNoticeTypeFields: boolean;
  isMadlib: boolean;
}) {
  if (isMadlib) {
    return 'Please answer the questions and fill in the required information in the blue prompts to complete your notice.';
  }

  if (hasNoticeTypeFields) {
    return "Tell us what kind of notice you want to place, and we'll help you create or format it.";
  }

  return 'Upload a file or draft your notice in the text editor.';
}

type NoticeContentProps = {
  theme: any;
  activeStepId: string;
  id: string;
  notice: ESnapshotExists<ENoticeDraft>;
  newspaper: ESnapshotExists<EOrganization> | undefined;
  setUploading: (uploading: boolean) => void;
  next: () => void;
  previous?: () => void;
  onDisabledStepClick: (stepId: string) => void;
  placementActions: typeof PlacementActions;
  showSubmitFileWithoutFormattingModal: boolean;
  renderSubmitFileWithoutFormattingModal: () => React.ReactNode;
};

const DEFAULT_DISPLAY_COLUMNS = 2;

export type ConfirmationModalHandlers = {
  confirm: (value: void | PromiseLike<void>) => void;
  cancel: (value: void | PromiseLike<void>) => void;
};

const NoticeContentStep = React.forwardRef(
  (props: NoticeContentProps, ref: React.Ref<any>) => {
    const {
      activeStepId,
      id,
      notice,
      newspaper,
      setUploading,
      next,
      previous,
      onDisabledStepClick,
      placementActions,
      showSubmitFileWithoutFormattingModal,
      renderSubmitFileWithoutFormattingModal
    } = props;
    const dispatch = useAppDispatch();
    const placement = useAppSelector(placementSelector);
    const editing = useAppSelector(selectIsEditing);
    const isPublisher = useAppSelector(selectIsPublisher);
    const isCustomNoticeType = useAppSelector(selectIsCustomNoticeType);
    const isDisplayNoticeType = useAppSelector(selectIsDisplayNoticeType);

    const windowSize = useWindowResize();

    const [uploadLocation, setUploadLocation] = useState('');
    const [uploadedFileState, setUploadedFileState] = useState<{
      uploadedFilesAndRefs: FileWithUploadRef[];
      userAction: boolean;
    } | null>(null);
    const [parsing, setParsing] = useState(false);
    const [showEraseContentModal, setShowEraseContentModal] = useState<
      ConfirmationModalHandlers | null | false
    >();

    const [
      submitLargeFileWithoutFormatting,
      setSubmitLargeFileWithoutFormatting
    ] = useState(false);

    // step metadata state
    const [active, setActive] = useState(false);
    const [initialEditorState, setInitialEditorState] = useState<string | null>(
      ''
    );
    const [copyPasteContent, setCopyPasteContent] = useState(false);

    // PDF state
    // TODO: do we actually use this variable meaningfully? can we delete it and all the excess logic around it?
    const [pdf, setPdf] = useState<Blob>();

    const [keepWaitingForLargeFile, setKeepWaitingForLargeFile] = useState(
      false
    );

    const [
      shouldGrayscalePDFDisplays,
      setShouldGrayscalePDFDisplays
    ] = useState(false);
    const selectedNoticeType = useAppSelector(state =>
      selectCurrentlySelectedNoticeType(state, newspaper)
    );
    const isTypeform = !!selectedNoticeType?.isTypeform;
    const madlibConfigPath = selectedNoticeType?.madlib;

    const [isTypeformComplete, setIsTypeformComplete] = useState(
      !!notice.data()?.text
    );

    const inDuplicationFlow = getLocationParams().get('duplicate') === 'true';

    const shouldShowContentPreparedSelect = useAppSelector(state =>
      selectHasStateLevelNoticeTypes(state, newspaper)
    );
    const shouldShowNoticeTypeFields = useAppSelector(state =>
      selectShouldShowNoticeTypeFields(state, newspaper)
    );

    /**
     * For KS or CO default typeforms when publisher edits/duplicates a notice:
     * 1) If notice placed with "Yes" option using Custom Typeform
     * 2) If notice placed with "No" option
     * "No" will be preselected
     * Otherwise "Yes" will be pre-selected
     */
    const selectedContentPreparedValue =
      shouldShowNoticeTypeFields &&
      !isCustomNoticeType &&
      !isDisplayNoticeType &&
      (editing || inDuplicationFlow)
        ? 'yes'
        : 'no';

    const [
      selectedContentPreparedOption,
      setSelectedContentPreparedOption
    ] = useState(
      editing || inDuplicationFlow ? selectedContentPreparedValue : ''
    );

    const shouldShowNoticeTypeSelect =
      useAppSelector(state => selectHasNoticeTypeSelect(state, newspaper)) ||
      selectedContentPreparedOption === 'yes';

    /* 
    We use this key to force re-mounting of tinymce component.
    This is necessary in order to reset the 'initialState' of mce.
    Unfortunately, attempts to make TinyMCE a normal controlled component
    have resulted in user-unfriendly behavior in the editor so far.
    */
    const [mceKey, setMCEKey] = useState(1);

    // Mablibs
    const [templateStyles, setTemplateStyles] = useState<ETemplateStyles>({
      id: 'unknown'
    });
    const [isMadlibComplete, setIsMadlibComplete] = useState(false);
    const [showImagesRateWarning, setShowImagesRateWarning] = useState(false);
    const [
      isNoticeFormattingOptionSelected,
      setIsNoticeFormattingOptionSelected
    ] = useState(placement.processedDisplay);

    const [isRunningOCROnDisplay, setIsRunningOCROnDisplay] = useState(false);

    const allowMultipleDisplayFileUploads = !!newspaper?.data()
      .allowMultipleDisplayFileUploads;

    /**
     * For some reason we have both confirmedText and confirmedHtml in placement
     * In theory, confirmedText is (text || confirmedHtml), and text
     * is the content generated by typeform, but for some reason
     * confirmedText was _not_ getting set while confirmedHtml was
     */
    const confirmedHtml = useAppSelector(selectConfirmedHtml);

    const shouldLoadMadlibForNoticeType = useAppSelector(state =>
      selectShouldLoadMadlibForNoticeType(state, newspaper)
    );

    // Note: this will return true for newspapers with state-level notice types
    // even if you select NO in the "Content Prepared" step
    const shouldLoadTypeformForNoticeType = useAppSelector(state =>
      selectShouldLoadTypeformForNoticeType(state, newspaper)
    );

    const showDisabledEditorAfterTypeformMessage =
      !isPublisher &&
      (isTypeform || shouldLoadTypeformForNoticeType) && // if selected notice type is typeform in edit flow, it should still be disabled
      !!confirmedHtml && // check that there is actually typeform content
      !shouldLoadMadlibForNoticeType;

    useEffect(() => {}, [windowSize]);

    useEffect(() => {
      let active = true;
      const checkShouldGrayscalePDFDisplays = async () => {
        const parent = await newspaper?.data().parent?.get();
        if (active) {
          setShouldGrayscalePDFDisplays(
            newspaper?.data().grayscalePDFDisplayUploads ??
              parent?.data()?.grayscalePDFDisplayUploads ??
              false
          );
        }
      };

      void checkShouldGrayscalePDFDisplays();
      return () => {
        active = false;
      };
    }, [newspaper?.id]);

    const rateSnap = useFirestoreSnapshot(placement?.rate);

    const logosInLinersEnabled = getBooleanFlag(
      LaunchDarklyFlags.ENABLE_LOGOS_IN_LINERS,
      false
    );

    const noticeTypeAllowImages =
      exists(newspaper) &&
      noticeTypeSupportsImagesInLiners(newspaper, placement.noticeType);
    const rateAllowImages =
      exists(rateSnap) && rateSupportsImagesInLiners(rateSnap);

    // Images are allowed in liners if the flag is on, the notice type/organization explcitly
    // allows it, and the rate allows it.
    const allowImages =
      logosInLinersEnabled && noticeTypeAllowImages && rateAllowImages;

    const parseUploadedFiles = async (
      resetPostWithoutFormatting: boolean,
      resetTextAndFormatting: boolean
    ) => {
      if (
        !uploadedFileState ||
        !uploadedFileState.uploadedFilesAndRefs.length ||
        !exists(newspaper)
      ) {
        return;
      }

      // we only get and parse file uploads if we are not in duplication or edit flows
      if (resetTextAndFormatting) {
        setParsing(true);

        const parsingTimer = setTimeout(() => {
          placementActions.setFormattingError(
            'File Unable To Process under 25 secs'
          );
        }, 25000);

        if (uploadedFileState.uploadedFilesAndRefs.length === 1) {
          try {
            const tempAdTypeRecommendation = await getAdTypeRecommendationFromFileWithUploadRef(
              uploadedFileState.uploadedFilesAndRefs[0],
              allowImages
            );
            if (
              tempAdTypeRecommendation === AdTypeRecommendation.AsyncDisplay &&
              !newspaper.data().restrictSubmitWithoutFormatting
            ) {
              dispatch(
                PlacementActions.setShowPlacementErrors({
                  largeFile: true
                })
              );
            }
          } catch (e) {
            logAndCaptureException(
              ColumnService.WEB_PLACEMENT,
              e,
              'Unable to retrieve adTypeRecommendation from getAdTypeRecommendationFromFileWithUploadRef'
            );
          }
        }

        const arrOfFileProperties: FileWithProperties[] = uploadedFileState.uploadedFilesAndRefs
          ? (
              await Promise.all(
                uploadedFileState.uploadedFilesAndRefs.map(
                  async uploadedFileAndRef => {
                    const sanitizedName = uploadedFileAndRef
                      ? sanitize(uploadedFileAndRef.file.name)
                      : '';
                    const {
                      fileNameMinusExtension: originalName,
                      verifiedExtension: originalFormat
                    } = getVerifiedExtensionFromFileName(sanitizedName);
                    return {
                      uploadedFile: uploadedFileAndRef.file,
                      sanitizedName,
                      originalName,
                      originalFormat,
                      shouldGrayscalePDFDisplays,
                      fileType: getFileTypeFromFile(uploadedFileAndRef.file),
                      relatedUploadRef: uploadedFileAndRef.uploadRef,
                      linkToUploadedFile: await uploadedFileAndRef.uploadRef.getDownloadURL()
                    };
                  }
                )
              )
            ).filter(
              (fileProps): fileProps is FileWithProperties => !!fileProps
            )
          : [];

        const unprocessedFilesToAttach: ENoticeFileNoticeContent[] = arrOfFileProperties.map(
          fileProperties => ({
            type: NoticeFileTypes.display_ad_component,
            originalFirebaseStoragePath:
              fileProperties.relatedUploadRef.fullPath,
            linkToUploadedFile: fileProperties.linkToUploadedFile,
            originalFileName: fileProperties.originalName,
            sanitizedFileName: fileProperties.sanitizedName,
            fileFormat: fileProperties.originalFormat || null,
            fileType: fileProperties.fileType || null
          })
        );
        placementActions.setFilesToAttach(unprocessedFilesToAttach);
        if (
          (placement.postWithoutFormatting && !resetPostWithoutFormatting) ||
          unprocessedFilesToAttach.length > 1
        ) {
          if (!placement.postWithoutFormatting) {
            placementActions.setPostWithoutFormatting(true);
          }
          setParsing(false);
          setUploading(false);
          clearTimeout(parsingTimer);
          return;
        }

        const arrOfFileChanges = await Promise.all(
          arrOfFileProperties.map(fileProps =>
            getFileChanges(fileProps, newspaper, placement.squashable, {
              allowImages
            })
          )
        );

        // If any of the files has an image and all conditions are right for images
        // besides the rate, we want to show a warning. We only show this to publishers
        // because they can change rates. Advertisers (mostly) cannot.
        const someFileHasImages = arrOfFileChanges.some(fc => !!fc?.hasImage);
        if (
          isPublisher &&
          someFileHasImages &&
          !rateAllowImages &&
          allowImagesWithDifferentRate
        ) {
          setShowImagesRateWarning(true);
        }

        if (
          arrOfFileChanges.some(
            fileChanges =>
              fileChanges?.file.type === NoticeFileTypes.finalized_display_ad
          ) &&
          resetTextAndFormatting
        ) {
          /**
           * If we are resetting the notice formatting as a result of a successful AffinityX sync,
           * we should reset the number of columns to the number synced to AffinityX. Otherwise,
           * we should reset to the default number of columns.
           */
          const noticeSyncEvents = await getFirebaseContext()
            .eventsRef<SyncEvent<ManualBuildAdRequestEvent>>()
            .where('type', '==', MANUAL_BUILD_AD_REQUEST_SYNC)
            .where('notice', '==', notice.data()?.original)
            .orderBy('createdAt', 'desc')
            .get();
          const mostRecentSuccessEvent = noticeSyncEvents.docs.find(syncEvent =>
            syncStatusMatchesCriteria(syncEvent.data().data.syncStatus, {
              categories: [SyncStatusCategory.success],
              statuses: []
            })
          );
          const triggerEventForSync = await mostRecentSuccessEvent
            ?.data()
            ?.trigger?.get();
          const columnsSyncedToAffinity = triggerEventForSync?.data()?.data
            ?.numberOfColumns;
          placementActions.setColumns(
            columnsSyncedToAffinity ??
              notice.data()?.columns ??
              DEFAULT_DISPLAY_COLUMNS
          );
        }

        const parseFileChanges = (
          changes: FileChanges | null,
          multipleFilesUploaded: boolean
        ) => {
          // If more than one file is uploaded, these properties will be reset anyway as we'll be forcing the
          // submit-without-formatting flow, so we only want to parse these changes if there is a single file uploaded
          if (changes && !multipleFilesUploaded && resetTextAndFormatting) {
            Object.keys(changes).forEach(key => {
              if (key === 'noticeType') {
                if (
                  placement.noticeType === NoticeType.custom.value ||
                  changes!.noticeType !== NoticeType.custom.value
                ) {
                  // Save previous notice type to reset to it if notice is converted to liner
                  const nonDisplayPreviousNoticeType =
                    placement.noticeType !== NoticeType.display_ad.value
                      ? placement.noticeType
                      : placement.previousNoticeType;
                  placementActions.setPreviousNoticeType(
                    nonDisplayPreviousNoticeType
                  );
                  placementActions.setNoticeType(changes!.noticeType);
                }
              }
              if (key === 'text') {
                setInitialEditorState(changes!.text!);
                placementActions.setNoticeText(changes!.text);
              }
              if (key === 'unusedConfirmedHtml') {
                placementActions.setUnusedConfirmedHtml(
                  changes!.unusedConfirmedHtml
                );
              }
              if (key === 'processedDisplay') {
                placementActions.setDisplayUrl(
                  changes!.file.firebaseStoragePath
                );
                placementActions.setProcessedDisplay(changes!.processedDisplay);
                placementActions.setConfirmedCrop(null);
                placementActions.setPdfStoragePath(null);
              }
            });
          }
          return changes?.file;
        };

        const noticeFiles = arrOfFileChanges.map(fileChanges =>
          parseFileChanges(fileChanges, arrOfFileChanges.length > 1)
        );
        const noticeContentFiles = noticeFiles.filter<ENoticeFileNoticeContent>(
          isNoticeContentData
        );
        const validNoticeFiles = noticeContentFiles.map(noticeContentFile => {
          // If the files being submitting are components of a not-yet-built display ad, the type on the file should reflect that
          if (noticeContentFiles.length > 1) {
            return {
              ...noticeContentFile,
              type: NoticeFileTypes.display_ad_component
            };
          }

          return noticeContentFile;
        });

        placementActions.setFilesToAttach(validNoticeFiles);
        if (validNoticeFiles.length > 1) {
          // if we've uploaded more than 1 file, we force the user to submit without formatting
          placementActions.setPostWithoutFormatting(true);
        } else if (
          placement.postWithoutFormatting &&
          resetPostWithoutFormatting
        ) {
          // if the number of files uploaded has been reduced from multiple to 1, we allow the user to format the notice
          placementActions.setPostWithoutFormatting(false);
        }
        clearTimeout(parsingTimer);
      }

      setUploading(false);
      placementActions.saveDraft();
      setKeepWaitingForLargeFile(false);
      // we should clear all showPlacementErrors because the file processed at this time -- not doing this can cause to show both
      // the Editor and large file options modal (if in case some error was true)
      placementActions.setShowPlacementErrors({
        wait: false,
        largeFile: false
      });
      setParsing(false);
    };

    useEffect(() => {
      // we should reset this property any time the uploaded file state changes
      setSubmitLargeFileWithoutFormatting(false);
      if (!exists(newspaper)) {
        return;
      }

      if (
        uploadedFileState &&
        uploadedFileState.uploadedFilesAndRefs.length &&
        !parsing
      ) {
        // will be true if a user deletes a file and there is 1 file left
        const oneFileRemainingAfterDeletion =
          (placement.filesToAttach || []).length > 1 &&
          uploadedFileState.uploadedFilesAndRefs.length === 1;
        // will be true if there is only 1 file on placement and it is a formatted display ad
        const singleFileIsFinalizedDisplayAd =
          !!placement.filesToAttach &&
          placement.filesToAttach.length === 1 &&
          placement.filesToAttach[0].type ===
            NoticeFileTypes.finalized_display_ad;
        /**
         * We should undo a notice's `postWithoutFormatting` status (i.e., set from `true` => `false`) ONLY if:
         * 1. We are duplicating the notice
         * 2. A user deletes a file so that we no longer have multiple files (which cannot be formatted) and are left with 1
         * 3. The file on placement is a formatted display ad; this will typically happen (`postWithoutFormatting === true` && notice file is `finalized_display_ad` after an AffinityX sync)
         */
        const shouldResetPostWithoutFormatting =
          inDuplicationFlow ||
          oneFileRemainingAfterDeletion ||
          singleFileIsFinalizedDisplayAd;

        // true if a notice file was never processed, and therefore has not text or display content,
        // and if we are not in edit or duplication flows, since we do not want to reparse already uploaded files
        // in edit and duplication flows.
        const noFormattingOnPlacement =
          !placement.processedDisplay &&
          !placement.confirmedHtml &&
          !(editing || inDuplicationFlow);

        // true if there is only 1 file on placement and it is an AffinityX response file
        const singleFileIsAffinityX =
          singleFileIsFinalizedDisplayAd &&
          affinityXLibHelpers.isAffinityXResponseFileName(
            placement.filesToAttach![0].sanitizedFileName || ''
          );
        /**
         * We should re-process an uploaded file ONLY if:
         * 1. The change in state is the result of a user action (uploading or deleting a file)
         * 2. There are files in placement state but the notice does not have any formatting
         * 3. The only file in placement state is an AffinityX response file
         * */
        const shouldResetTextAndFormatting =
          uploadedFileState.userAction ||
          noFormattingOnPlacement ||
          singleFileIsAffinityX;

        // TODO: Investigate why we're not handling errors in file parsing here and add error handling if possible
        void parseUploadedFiles(
          shouldResetPostWithoutFormatting,
          shouldResetTextAndFormatting
        );
      }
    }, [
      uploadedFileState?.uploadedFilesAndRefs?.length,
      newspaper?.id,
      shouldGrayscalePDFDisplays
    ]);

    useEffect(() => {
      const filesToAttachNotInReactState =
        !!placement.filesToAttach?.length &&
        !uploadedFileState?.uploadedFilesAndRefs?.length;

      const setPlacementFilesOnState = async () => {
        const filesAndUploadRefsToSet: FileWithUploadRef[] = [];
        for (const placementFileToAttach of placement.filesToAttach || []) {
          if (!isNoticeContentData(placementFileToAttach)) {
            continue;
          }

          const fileAndUploadRefToSet = {
            // eslint-disable-next-line no-await-in-loop
            file: await getUploadedFileData(placementFileToAttach),
            uploadRef: Firebase.storage().ref(
              placementFileToAttach.originalFirebaseStoragePath
            )
          };

          filesAndUploadRefsToSet.push(fileAndUploadRefToSet);
        }

        setUploadedFileState({
          uploadedFilesAndRefs: filesAndUploadRefsToSet,
          userAction: false
        });
      };

      if (filesToAttachNotInReactState && uploadLocation) {
        void setPlacementFilesOnState();
      }
    }, [placement.filesToAttach?.length, uploadLocation]);

    const tiny = useRef() as RefObject<HTMLDivElement>;

    const { showHeightWarning } = useAppSelector(state =>
      selectShowHeightWarning(state, newspaper)
    );

    const contentPreparedFieldComplete =
      !!selectedContentPreparedOption || !shouldShowContentPreparedSelect;
    const contentPreparedHelpRequested =
      selectedContentPreparedOption === 'yes' ||
      !shouldShowContentPreparedSelect;

    const { madlibData, placedViaEmailAutomation } = placement;
    const madlibDataEmpty =
      !madlibData ||
      (isEmpty(madlibData.questionTemplateData) &&
        isEmpty(madlibData.templateData));

    // When editing notices filed with Typeform or email automation, we will show TinyMCE editor even if the notice type uses the Madlib flow
    const noticeFiledWithTypeform =
      selectedNoticeType?.typeform && editing && madlibDataEmpty;
    const noticeFiledWithAutoPlacement =
      editing && madlibDataEmpty && placedViaEmailAutomation;

    const madlibOverride = !!madlibData?.editorOverride;
    const advertiserMadlibOverride = !isPublisher && madlibOverride;

    // Show the Madlib edtor
    const shouldLoadMadlib =
      !!madlibConfigPath &&
      !noticeFiledWithTypeform &&
      !madlibOverride &&
      !noticeFiledWithAutoPlacement;

    // When both a Madlib and a Typeform are available, we choose the Madlib
    const shouldLoadTypeform =
      useAppSelector(state =>
        selectShouldLoadTypeformForNoticeType(state, newspaper)
      ) &&
      contentPreparedHelpRequested &&
      !isTypeformComplete &&
      !madlibOverride &&
      !shouldLoadMadlib;

    const noticeTypeSelectionIsComplete = Boolean(
      contentPreparedFieldComplete &&
        (selectedNoticeType || !contentPreparedHelpRequested)
    );

    // Show the normal 'MCE' editor.
    const shouldLoadMce =
      !shouldLoadMadlib &&
      !shouldLoadTypeform &&
      (!shouldShowNoticeTypeFields || noticeTypeSelectionIsComplete) &&
      !advertiserMadlibOverride;

    // This should not be necessary but we've had some issues with the boolean
    // logic above so this makes sure we make exactly one choice for editor.
    const editorToShow = shouldLoadMadlib
      ? 'madlib'
      : shouldLoadTypeform
      ? 'typeform'
      : shouldLoadMce
      ? 'mce'
      : 'none';

    const noticeTitleFieldConfig = useAppSelector(state =>
      selectNoticeTitleFieldConfig(state, newspaper)
    );

    const isComplete = () => {
      if (shouldLoadMadlib && !isMadlibComplete) {
        return false;
      }
      if (!placement.noticeType) return false;
      if (placement.postWithoutFormatting) return true;

      // This line should be below the check for `postWithoutFormatting` because
      // we want to be able to proceed even after a user has selected
      // 'submit without formatting' on the large file modal and the file
      // is still parsing
      if (parsing) return false;

      if (placement.confirmedHtml && !validateHtml(placement.confirmedHtml)) {
        return false;
      }

      if (
        !placement.displayParams ||
        !Object.keys(placement.displayParams).length
      ) {
        return false;
      }

      if (showHeightWarning) return false;

      // When the newspaper requires notice headers, the user must enter a non
      // empty header text to continue
      if (noticeTitleFieldConfig.required && !placement.headerText?.trim()) {
        return false;
      }

      return !!(isDisplayNoticeType
        ? placement.confirmedCrop &&
          placement.displayParams &&
          pdf &&
          placement.displayParams.height
        : placement.confirmedHtml && placement.confirmedHtml.length);
    };
    const complete = isComplete();

    useEffect(() => {
      const mceNotInitialized = !!(
        !placement.confirmedHtml &&
        placement.publicationDates &&
        placement.newspaper &&
        initialEditorState !== placement.confirmedHtml
      );

      if (mceNotInitialized) {
        setInitialEditorState(placement.confirmedHtml);
        setMCEKey(mceKey + 1);
      }

      if (placement.confirmedHtml) {
        setInitialEditorState(placement.confirmedHtml);
      }
    }, [placement.confirmedText, Boolean(placement.confirmedHtml)]);

    useEffect(() => {
      if (placement.original) {
        // notice files will be uploaded to a folder named with
        // the actual notice id (not the draft's id)
        setUploadLocation(`/documentcloud/${placement.original.id}`);
      }
    }, [placement.original]);

    useEffect(() => {
      if (placement.postWithoutFormatting) {
        placementActions.setDisplayParams({});
        placementActions.setProcessedDisplay(null);
        placementActions.setConfirmedCrop(null);
        setInitialEditorState('');
      }
    }, [placement.postWithoutFormatting]);

    useEffect(() => {
      if (placement.processedDisplay && newspaper?.data().disableDisplay) {
        void convertDisplayToText();
      }
    }, [newspaper?.data().disableDisplay, placement.processedDisplay]);

    const convertDisplayToText = async (useCloudConvert = false) => {
      // If we press on the convert display to text button, and there is no text from OCR, we run OCR on the display.
      if (placement.unusedConfirmedHtml) {
        setInitialEditorState(placement.unusedConfirmedHtml || null);
        placementActions.setNoticeText(placement.unusedConfirmedHtml || null);
      } else if (placement.filesToAttach) {
        setIsRunningOCROnDisplay(true);
        const firstAttachedFile = placement.filesToAttach[0];
        const firstUploadedFile =
          uploadedFileState?.uploadedFilesAndRefs[0].file;
        const { fileType } = firstAttachedFile;
        let noticeText = '';
        // WORD
        if (
          fileType === FileType.WORD_DOC &&
          firstAttachedFile.originalFirebaseStoragePath
        ) {
          const { html } = await wordOrPDFToHtml(
            firstAttachedFile.originalFirebaseStoragePath,
            newspaper?.data()?.cleanVariant,
            placement.squashable,
            undefined
          );
          noticeText = html;
        }
        // CSV
        else if (fileType === FileType.CSV && firstUploadedFile) {
          const html = await csvToHtml(firstUploadedFile);
          noticeText = html;
        } else if (
          fileType === FileType.PDF &&
          firstAttachedFile.originalFirebaseStoragePath &&
          useCloudConvert
        ) {
          const { html } = await wordOrPDFToHtml(
            firstAttachedFile.originalFirebaseStoragePath,
            newspaper?.data()?.cleanVariant,
            placement.squashable,
            undefined
          );
          noticeText = html;
        }

        // Excel and other, run-ocr also works on other files, it just won't preserve the formatting of csv/word
        else {
          const useColumnCDN = getBooleanFlag(
            LaunchDarklyFlags.ENABLE_COLUMN_CDN
          );
          const transformedDisplayUrl = cdnIfy(
            placement.displayUrl || placement.unusedDisplay,
            { useColumnCDN }
          );
          const { text } = await api.post('documents/run-ocr', {
            url: transformedDisplayUrl
          });
          noticeText = text;
        }
        setInitialEditorState(noticeText);
        placementActions.setNoticeText(noticeText);
        setIsRunningOCROnDisplay(false);
      }

      if (!placement.unusedDisplay) {
        placementActions.setUnusedDisplay(placement.displayUrl);
      }

      placementActions.setProcessedDisplay(false);
      placementActions.setDisplayUrl(null);
      placementActions.setDisplayParams(null);

      // Custom filing notices converted to liners should be assigned correct notice types
      if (placement.noticeType === NoticeType.display_ad.value) {
        if (
          placement.previousNoticeType &&
          placement.previousNoticeType !== NoticeType.custom.value
        ) {
          placementActions.setNoticeType(placement.previousNoticeType);
        } else {
          placementActions.setNoticeType(NoticeType.custom.value);
        }
      }
      placementActions.saveDraft();
    };

    const convertTextToDisplay = async (): Promise<void> => {
      if (placement.confirmedHtml) {
        placementActions.setUnusedConfirmedHtml(placement.confirmedHtml);
      }

      if (placement.unusedDisplay) {
        placementActions.setDisplayUrl(placement.unusedDisplay);
      }

      placementActions.setUnusedDisplay(null);
      placementActions.setNoticeText(null);
      placementActions.setProcessedDisplay(true);
      placementActions.setNoticeType(NoticeType.display_ad.value);

      await initPDF();
    };

    const onExit = async () => {
      const { publicationDates } = placement;

      dispatch(syncDynamicHeadersChange(newspaper));

      placementActions.confirmSchedule({
        publicationDates,
        dynamicFooter: placement.dynamicFooter,
        footerFormatString: null
      });

      if (
        placement.noticeType === NoticeType.display_ad.value &&
        !placement.postWithoutFormatting
      ) {
        if (!placement.draft?.id) {
          console.error('Draft does not exist on placement');
          return;
        }

        const data = await api.post('documents/set-display-crop', {
          draftId: placement.draft.id
        });

        const { pdfStoragePath, error } = data;

        if (error) {
          placementActions.setPlacementError(new PlacementError());
          logAndCaptureException(
            ColumnService.WEB_PLACEMENT,
            error,
            'Failed to crop file',
            {
              draftId: placement.draft?.id
            }
          );
          return previous && previous();
        }
        placementActions.setPdfStoragePath(pdfStoragePath);
      }

      await dispatch(updateDraftFiles());
    };

    useImperativeHandle(ref, () => ({
      animateIn() {
        setActive(true);
      }
    }));

    useEffect(() => {
      if (!tiny.current) return;
      tiny.current.style.height = '60vh';
    }, [active]);

    useEffect(() => {
      setActive(activeStepId === id);
    }, [activeStepId]);

    useEffect(() => {
      if (copyPasteContent) {
        setTimeout(() => {
          setParsing(false);
          setCopyPasteContent(false);
        }, 1000);
      }
    }, [copyPasteContent]);

    const initPDF = async () => {
      if (!placement.displayUrl) {
        return;
      }

      const fileURL = await Firebase.storage()
        .ref()
        .child(placement.displayUrl)
        .getDownloadURL();

      const resp = await fetch(fileURL);
      const blob = await resp.blob();
      setPdf(blob);
      setParsing(false);
    };

    useEffect(() => {
      if (!placement.processedDisplay) {
        return setPdf(undefined);
      }

      void initPDF();
    }, [
      placement.processedDisplay,
      placement.adTemplate?.id,
      placement.displayUrl
    ]);

    // Each time the placement template id is updated, we fetch the template
    // styles from the backend.
    useEffect(() => {
      const fetchTemplateStyles = async () => {
        const templateId = placement?.adTemplate?.id;
        if (!templateId) {
          return;
        }

        try {
          const res = (await api.post('templates/styles', {
            templateId
          })) as EResponseTypes['templates/styles'];

          if (res.success === true) {
            setTemplateStyles(res.styles);
          }
        } catch (e) {
          logAndCaptureException(
            ColumnService.WEB_PLACEMENT,
            e,
            'Failed to get styles for template',
            {
              templateId
            }
          );
        }
      };

      void fetchTemplateStyles();
    }, [placement?.adTemplate?.id]);

    // To indicate if only the rate was the problem
    const allowImagesWithDifferentRate =
      noticeTypeAllowImages && !rateAllowImages;

    useEffect(() => {
      // Whenever 'allowImages' changes, we need to refresh the editor
      // Before refreshing the editor, set confirmedHtml as initial editor state to avoid
      // loading of old content in the editor after refreshing
      setInitialEditorState(placement.confirmedHtml);
      setMCEKey(mceKey + 1);

      // Remove images from the notice content if conditions change to not allow them.
      const noticeHtml = placement.confirmedHtml;
      if (
        exists(rateSnap) &&
        noticeHtml &&
        shouldRemoveImagesFromLiners(rateSnap, noticeHtml)
      ) {
        const sanitizedHtml = sanitizeNoticeContentHtml(noticeHtml, {
          allowImages: false
        });

        setInitialEditorState(sanitizedHtml);
      }
    }, [allowImages]);

    const title = editing ? 'Edit Notice Content' : "Let's create your notice";

    const caption = getStepCaption({
      hasNoticeTypeFields: shouldShowNoticeTypeFields,
      isMadlib: !!madlibConfigPath
    });

    return (
      <ScrollStep
        id={id}
        next={async () => {
          await onExit();
          next();
        }}
        previous={
          previous
            ? async () => {
                await onExit();
                previous();
              }
            : undefined
        }
        complete={placement.postWithoutFormatting || (!parsing && complete)}
        title={title}
        caption={caption}
        onDisabledStepClick={onDisabledStepClick}
      >
        <div className="space-y-6">
          {showImagesRateWarning && (
            <div>
              <Alert
                id="images-rate-warning"
                status="warning"
                icon={<ExclamationCircleIcon className="w-5 h-5" />}
                title="The file you uploaded contains an image, but the currently selected rate cannot price ads with images. If you'd like to edit the text of this ad and include the image, please change the rate and upload the file again."
                onDismiss={() => setShowImagesRateWarning(false)}
              />
            </div>
          )}
          {shouldShowNoticeTypeFields && shouldShowContentPreparedSelect && (
            <div>
              <SelectContentPrepared
                value={selectedContentPreparedOption}
                onChange={setSelectedContentPreparedOption}
              />
            </div>
          )}
          {shouldShowNoticeTypeFields && shouldShowNoticeTypeSelect && (
            <div>
              <SelectNoticeType
                newspaper={newspaper}
                onChange={() => setIsTypeformComplete(false)}
              />
            </div>
          )}
          {editorToShow === 'typeform' && (
            <div style={{ height: '800px' }}>
              <NoticeContentStepTypeformEmbed
                formId={selectedNoticeType?.typeform}
                setIsTypeformComplete={setIsTypeformComplete}
              />
            </div>
          )}
          {editorToShow === 'madlib' && madlibConfigPath && madlibData && (
            <>
              <NoticeContentAlerts
                newspaper={newspaper}
                setSubmitLargeFileWithoutFormatting={
                  setSubmitLargeFileWithoutFormatting
                }
              />

              {isPublisher && (
                <MadlibOverride
                  product={Product.Notice}
                  onClick={() => {
                    placementActions.setMadlibData(
                      clearDataForEditorOverride(madlibData)
                    );
                  }}
                />
              )}

              <NoticeEditorMadlib
                madlibConfigPath={madlibConfigPath}
                onEditorUpdate={renderedHtml => {
                  placementActions.setNoticeText(renderedHtml);
                  placementActions.saveDraft();
                }}
                templateStyles={templateStyles}
                madlibData={madlibData}
                onTemplateDataChange={newMadlibData => {
                  placementActions.setMadlibData(newMadlibData);
                  if (
                    newMadlibData.metadata?.noticePrice &&
                    newMadlibData.metadata?.noticePrice !== placement.fixedPrice
                  ) {
                    placementActions.setFixedPrice(
                      newMadlibData.metadata?.noticePrice
                    );
                  }
                }}
                noticeHandlebarData={{
                  publicationDates: placement.publicationDates
                }}
                setIsMadlibComplete={setIsMadlibComplete}
                key={madlibConfigPath}
                newspaper={newspaper}
              />
            </>
          )}

          {madlibOverride && (
            <MadlibOverrideAlert
              product={Product.Notice}
              isPublisher={isPublisher}
            />
          )}

          {editorToShow === 'mce' && (
            <div
              className={classNames({
                'space-y-6': !showDisabledEditorAfterTypeformMessage
              })}
            >
              <UploadButton
                allowMultipleDisplayFileUploads={
                  allowMultipleDisplayFileUploads
                }
                isTypeform={isTypeform}
                parsing={parsing}
                placementActions={placementActions}
                uploadedFileState={uploadedFileState}
                setUploadedFileState={setUploadedFileState}
                newspaper={newspaper}
                setInitialEditorState={setInitialEditorState}
                setMCEKey={setMCEKey}
                mceKey={mceKey}
                setShowEraseContentModal={setShowEraseContentModal}
                uploadLocation={uploadLocation}
                allowImages={allowImages}
                setUploading={setUploading}
                showEraseContentModal={showEraseContentModal}
                showSubmitFileWithoutFormattingModal={
                  showSubmitFileWithoutFormattingModal
                }
                renderSubmitFileWithoutFormattingModal={
                  renderSubmitFileWithoutFormattingModal
                }
                setIsNoticeFormattingOptionSelected={
                  setIsNoticeFormattingOptionSelected
                }
              />

              <NoticeContentInner
                placementActions={placementActions}
                newspaper={newspaper}
                notice={notice}
                mceKey={mceKey}
                setMCEKey={setMCEKey}
                initialEditorState={initialEditorState}
                setInitialEditorState={setInitialEditorState}
                setUploadedFileState={setUploadedFileState}
                setShowEraseContentModal={setShowEraseContentModal}
                convertDisplayToText={convertDisplayToText}
                convertTextToDisplay={convertTextToDisplay}
                submitLargeFileWithoutFormatting={
                  submitLargeFileWithoutFormatting
                }
                setSubmitLargeFileWithoutFormatting={
                  setSubmitLargeFileWithoutFormatting
                }
                templateStyles={templateStyles}
                allowImages={allowImages}
                parsing={parsing}
                setParsing={setParsing}
                keepWaitingForLargeFile={keepWaitingForLargeFile}
                setKeepWaitingForLargeFile={setKeepWaitingForLargeFile}
                isTypeform={isTypeform}
                setCopyPasteContent={setCopyPasteContent}
                tiny={tiny}
                setIsNoticeFormattingOptionSelected={
                  setIsNoticeFormattingOptionSelected
                }
                isNoticeFormattingOptionSelected={
                  isNoticeFormattingOptionSelected
                }
                showDisabledEditorAfterTypeformMessage={
                  showDisabledEditorAfterTypeformMessage
                }
                isRunningOCROnDisplay={isRunningOCROnDisplay}
              />
            </div>
          )}
        </div>
        <style>{`
          .tox-notifications-container {
            display: none;
          }
        `}</style>
      </ScrollStep>
    );
  }
);

const mapStateToProps = (state: { placement: EPlacement }) => ({
  placement: state.placement
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withStyles(styles, { withTheme: true })(NoticeContentStep)
);
