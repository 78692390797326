export enum FileType {
  CSV = 'csv',
  EPS = 'eps',
  EXCEL = 'excel',
  HEIC = 'heic',
  HEIF = 'heif',
  HTML = 'html',
  IDML = 'idml',
  JPG = 'jpg',
  JSON = 'json',
  PDF = 'pdf',
  PNG = 'png',
  RTF = 'rtf',
  TEXT = 'text',
  TIF = 'tif',
  WORD_DOC = 'word-doc',
  XML = 'xml',
  ZIP = 'zip'
}

export enum Extensions {
  csv = FileType.CSV,
  doc = FileType.WORD_DOC,
  docx = FileType.WORD_DOC,
  eps = FileType.EPS,
  html = FileType.HTML,
  idml = FileType.IDML,
  jpeg = FileType.JPG,
  jpg = FileType.JPG,
  json = FileType.JSON,
  pdf = FileType.PDF,
  png = FileType.PNG,
  rtf = FileType.RTF,
  tif = FileType.TIF,
  tiff = FileType.TIF,
  txt = FileType.TEXT,
  xls = FileType.EXCEL,
  xlsx = FileType.EXCEL,
  xml = FileType.XML,
  zip = FileType.ZIP,
  heic = FileType.HEIC,
  heif = FileType.HEIF
}

/**
 * This is a non-exhaustive list! Just the ones we use most commonly
 * see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export enum MimeTypes {
  APP_EXCEL = 'application/vnd.ms-excel',
  APP_EXCEL_XML = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  APP_JSON = 'application/json',
  APP_PDF = 'application/pdf',
  APP_RTF = 'application/rtf',
  APP_WORD = 'application/msword',
  APP_WORD_XML = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  APP_XML = 'application/xml',
  APP_ZIP = 'application/zip',
  IMG_JPG = 'image/jpeg',
  IMG_PNG = 'image/png',
  IMG_TIFF = 'image/tiff',
  TEXT_CSV = 'text/csv',
  TEXT_HTML = 'text/html',
  TEXT_PLAIN = 'text/plain',
  // 'application/xml' is recommended but 'text/xml' is still in use
  TEXT_XML = 'text/xml',
  TTF = 'font/ttf'
}

/**
 *
 * @param ext a file extension
 * @returns a validated Extensions enum type or undefined
 */
const getExtensionEnumFromString = (ext: string) => {
  switch (ext.toLowerCase()) {
    case 'pdf':
      return Extensions.pdf;
    case 'idml':
      return Extensions.idml;
    case 'csv':
      return Extensions.csv;
    case 'xlsx':
      return Extensions.xlsx;
    case 'xls':
      return Extensions.xls;
    case 'doc':
      return Extensions.doc;
    case 'docx':
      return Extensions.docx;
    case 'txt':
      return Extensions.txt;
    case 'tif':
      return Extensions.tif;
    case 'tiff':
      return Extensions.tiff;
    case 'png':
      return Extensions.png;
    case 'jpg':
      return Extensions.jpg;
    case 'jpeg':
      return Extensions.jpeg;
    case 'rtf':
      return Extensions.rtf;
    case 'zip':
      return Extensions.zip;
    case 'html':
      return Extensions.html;
    case 'xml':
      return Extensions.xml;
    case 'json':
      return Extensions.json;
    case 'eps':
      return Extensions.eps;
    case 'heic':
      return Extensions.heic;
    case 'heif':
      return Extensions.heif;
    default:
      return undefined;
  }
};

const isRecognizedExtensionString = (ext: string) => {
  return !!getExtensionEnumFromString(ext);
};

export const getVerifiedExtensionFromFileName = (
  fileName: string
): {
  fileNameMinusExtension: string;
  verifiedExtension: string | undefined;
} => {
  const maybeExtension = fileName.split('.').pop();
  if (maybeExtension && isRecognizedExtensionString(maybeExtension)) {
    return {
      fileNameMinusExtension: fileName.split('.').slice(0, -1).join('.'),
      verifiedExtension: maybeExtension
    };
  }

  return {
    fileNameMinusExtension: fileName,
    verifiedExtension: undefined
  };
};

// This is similar to getVerifiedExtensionFromFileName but for URLs
// It removes query parameters from the URL before extracting the file name
export const getVerifiedExtensionFromUrl = (
  url: string
): {
  fileNameMinusExtension: string;
  verifiedExtension: string | undefined;
} => {
  const urlParts = url.split('/');
  const fileName = urlParts[urlParts.length - 1].split('?')[0];
  return getVerifiedExtensionFromFileName(fileName);
};

const getFileTypeFromExtensionEnum = (
  ext: Extensions
): FileType | undefined => {
  switch (ext) {
    case Extensions.pdf:
      return FileType.PDF;
    case Extensions.idml:
      return FileType.IDML;
    case Extensions.csv:
      return FileType.CSV;
    case Extensions.xlsx:
    case Extensions.xls:
      return FileType.EXCEL;
    case Extensions.doc:
    case Extensions.docx:
      return FileType.WORD_DOC;
    case Extensions.txt:
      return FileType.TEXT;
    case Extensions.tif:
    case Extensions.tiff:
      return FileType.TIF;
    case Extensions.png:
      return FileType.PNG;
    case Extensions.jpg:
    case Extensions.jpeg:
      return FileType.JPG;
    case Extensions.rtf:
      return FileType.RTF;
    case Extensions.zip:
      return FileType.ZIP;
    case Extensions.html:
      return FileType.HTML;
    case Extensions.xml:
      return FileType.XML;
    case Extensions.json:
      return FileType.JSON;
    case Extensions.eps:
      return FileType.EPS;
    case Extensions.heic:
      return FileType.HEIC;
    case Extensions.heif:
      return FileType.HEIF;
    default:
      return undefined;
  }
};

export const getFileTypeFromExtensionString = (ext: string) => {
  const extensionEnum = getExtensionEnumFromString(ext);
  const fileType = extensionEnum && getFileTypeFromExtensionEnum(extensionEnum);
  return fileType;
};

export const getFileTypeFromFileName = (fileName: string) => {
  const { verifiedExtension } = getVerifiedExtensionFromFileName(fileName);
  if (!verifiedExtension) {
    return undefined;
  }
  const fileType = getFileTypeFromExtensionString(verifiedExtension);
  return fileType;
};

const getMimeTypeFromTypeString = (mimeTypeString: string) => {
  switch (mimeTypeString) {
    case 'application/vnd.ms-excel':
      return MimeTypes.APP_EXCEL;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return MimeTypes.APP_EXCEL_XML;
    case 'application/json':
      return MimeTypes.APP_JSON;
    case 'application/pdf':
      return MimeTypes.APP_PDF;
    case 'application/rtf':
      return MimeTypes.APP_RTF;
    case 'application/msword':
      return MimeTypes.APP_WORD;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return MimeTypes.APP_WORD_XML;
    case 'application/zip':
      return MimeTypes.APP_ZIP;
    case 'image/jpeg':
      return MimeTypes.IMG_JPG;
    case 'image/png':
      return MimeTypes.IMG_PNG;
    case 'image/tiff':
      return MimeTypes.IMG_TIFF;
    case 'text/csv':
      return MimeTypes.TEXT_CSV;
    case 'text/html':
      return MimeTypes.TEXT_HTML;
    case 'text/plain':
      return MimeTypes.TEXT_PLAIN;
    case 'application/xml':
      return MimeTypes.APP_XML;
    case 'text/xml':
      return MimeTypes.TEXT_XML;
    default:
      return undefined;
  }
};

export const getFileTypeFromMimeType = (
  mimeType: MimeTypes
): FileType | undefined => {
  switch (mimeType) {
    case MimeTypes.APP_EXCEL:
    case MimeTypes.APP_EXCEL_XML:
      return FileType.EXCEL;
    case MimeTypes.APP_JSON:
      return FileType.JSON;
    case MimeTypes.APP_PDF:
      return FileType.PDF;
    case MimeTypes.APP_RTF:
      return FileType.RTF;
    case MimeTypes.APP_WORD:
    case MimeTypes.APP_WORD_XML:
      return FileType.WORD_DOC;
    case MimeTypes.APP_XML:
    case MimeTypes.TEXT_XML:
      return FileType.XML;
    case MimeTypes.APP_ZIP:
      return FileType.ZIP;
    case MimeTypes.IMG_JPG:
      return FileType.JPG;
    case MimeTypes.IMG_PNG:
      return FileType.PNG;
    case MimeTypes.IMG_TIFF:
      return FileType.TIF;
    case MimeTypes.TEXT_CSV:
      return FileType.CSV;
    case MimeTypes.TEXT_HTML:
      return FileType.HTML;
    case MimeTypes.TEXT_PLAIN:
      return FileType.TEXT;
    default:
      return undefined;
  }
};

export const getFileTypeFromMimeTypeString = (mimeTypeString: string) => {
  const mimeType = getMimeTypeFromTypeString(mimeTypeString);
  const fileType = mimeType && getFileTypeFromMimeType(mimeType);
  return fileType;
};
