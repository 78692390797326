import React from 'react';
import {
  DocumentArrowDownIcon,
  PlusCircleIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import Firebase from 'EnoticeFirebase';
import { CompactSwitchControlledCard } from 'lib/components/Card/CompactSwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnButton } from 'lib/components/ColumnButton';
import FileUpload from 'lib/components/FileUpload';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { Label } from 'lib/components/Label';
import { TextField } from 'lib/components/TextField';
import { MimeTypes } from 'lib/types/mime';
import { AdditionalLink } from 'lib/types/productSiteSetting';

const DEFAULT_ADDITIONAL_LINK = {
  description: '',
  link: '',
  backgroundImageStoragePath: ''
};

function AdditionalLinkCard({
  link,
  onChange,
  onDelete,
  index
}: {
  link: AdditionalLink;
  onChange: (value: AdditionalLink) => void;
  onDelete: () => void;
  index: number;
}) {
  return (
    <div className="col-span-1 grid grid-cols-2 gap-4">
      <GridInput fullWidth>
        <div className="flex justify-between items-center">
          <div>Additional Link {index + 1}</div>
          <div onClick={onDelete}>
            <TrashIcon className="w-6 h-6 cursor-pointer hover:text-column-red-500" />
          </div>
        </div>
      </GridInput>
      <GridInput>
        <TextField
          id={`additional-link-name-${index}`}
          labelText="Name"
          value={link.name}
          onChange={value => {
            onChange({ ...link, name: value });
          }}
          required
          placeholder="ex. Legal Notices"
        />
      </GridInput>
      <GridInput>
        <TextField
          id={`additional-link-link-${index}`}
          placeholder="https://..."
          labelText="Link"
          value={link.link}
          onChange={value => {
            onChange({ ...link, link: value });
          }}
          type="url"
          required
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          id={`additional-link-description-${index}`}
          labelText="Description"
          value={link.description}
          onChange={value => {
            onChange({ ...link, description: value });
          }}
          required
          placeholder="View more..."
        />
      </GridInput>
      <GridInput fullWidth>
        {link.backgroundImageStoragePath ? (
          <div className="flex flex-col gap-2">
            <Label id={`additional-link-cover-${index}`}>Cover Image</Label>
            <FileUploadListItem
              firebaseStoragePath={link.backgroundImageStoragePath}
              viewButtonText="Download"
              viewButtonIcon={<DocumentArrowDownIcon className="w-6 h-6" />}
              storage={Firebase.storage()}
              onDelete={() => {
                onChange({ ...link, backgroundImageStoragePath: '' });
              }}
            />
          </div>
        ) : (
          <FileUpload
            id={`additional-link-cover-${index}`}
            storage={Firebase.storage()}
            uploadFolder={`custom-documents/permalink/additional-links`}
            onFileUpload={async filesAndRefs => {
              const [newFileAndRef] = filesAndRefs;
              onChange({
                ...link,
                backgroundImageStoragePath: newFileAndRef.uploadRef.fullPath
              });
            }}
            acceptFileTypes={[MimeTypes.IMG_PNG, MimeTypes.IMG_JPG]}
            required
          />
        )}
      </GridInput>
    </div>
  );
}

export default function SiteSettingsAdditionalLinks({
  value: additionalLinks,
  onChange
}: {
  value: AdditionalLink[];
  onChange: (value: AdditionalLink[]) => void;
}) {
  const addNewAdditionalLink = () => {
    onChange([
      ...additionalLinks,
      {
        name: `Additional Link ${additionalLinks.length + 1}`,
        ...DEFAULT_ADDITIONAL_LINK
      }
    ]);
  };

  return (
    <CompactSwitchControlledCard
      labelProps={{
        value: additionalLinks.length > 0,
        onChange: () => {
          if (additionalLinks.length > 0) {
            onChange([]);
          } else {
            addNewAdditionalLink();
          }
        },
        label: 'Show additional links',
        description: 'Show external links not related to classifieds in Column in your classifieds directory' as `${Capitalize<string>}.`
      }}
    >
      <div className="flex flex-col gap-6">
        {additionalLinks.map((link, index) => (
          <AdditionalLinkCard
            key={index}
            link={link}
            index={index}
            onChange={newLinkValue => {
              const newLinks = [...additionalLinks];
              newLinks[index] = newLinkValue;
              onChange(newLinks);
            }}
            onDelete={() => {
              const newLinks = [...additionalLinks];
              newLinks.splice(index, 1);
              onChange(newLinks);
            }}
          />
        ))}
        <ColumnButton
          startIcon={<PlusCircleIcon className="w-6 h-6" />}
          buttonText="Add another external link"
          secondary
          type="button"
          link
          onClick={addNewAdditionalLink}
          id="add-more-links"
        />
      </div>
    </CompactSwitchControlledCard>
  );
}
