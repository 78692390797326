import { OrderEditableData, OrderModel } from 'lib/model/objects/orderModel';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useState } from 'react';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { useAppDispatch } from 'redux/hooks';
import { push } from 'connected-react-router';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { OrderDetailModel } from 'lib/model/objects/orderDetailModel';
import { Tooltip } from 'lib/components/Tooltip';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { safeAsync } from 'lib/safeWrappers';
import { RateType } from 'lib/enums';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import { InvoiceModel } from 'lib/model/objects/invoiceModel';
import { OrderInvoice } from 'lib/types/invoices';
import { getFirebaseContext } from 'utils/firebase';
import { sendCancelOrderRequest } from './actions/cancelOrder';
import MoreActions from './actions/MoreActions';
import { CancelOrderButton } from './actions/CancelOrderButton';
import { EditOrderButton } from './actions/EditOrderButton';

type OrderActionsProps = {
  order: OrderModel;
  orderDetail: OrderDetailModel;
  editData: OrderEditableData | null;
  anonymousUserAuthInfo: { authEmail: string; accessCode: string } | undefined;
};

export function OrderActions({
  order,
  orderDetail,
  editData,
  anonymousUserAuthInfo
}: OrderActionsProps) {
  const dispatch = useAppDispatch();
  const [invoiceIsUpdating, setInvoiceIsUpdating] = useState(false);

  const {
    isLoading: invoiceIsGetting,
    value: invoiceFromOrder
  } = useAsyncEffect<InvoiceModel<OrderInvoice> | null>({
    fetchData: async () => order.getInvoice(),
    dependencies: [order.id],
    errorConfig: {
      message: 'Error getting invoice on order details',
      service: ColumnService.DATABASE,
      tags: {
        orderId: order.id
      }
    }
  });

  const { value: isFlatRate } = useAsyncEffect({
    fetchData: async () => {
      const {
        response: newspaperOrders,
        error: newspaperOrderErrors
      } = await safeAsync(() => order.getNewspaperOrders())();

      if (newspaperOrderErrors || !newspaperOrders || !newspaperOrders.length) {
        logAndCaptureException(
          ColumnService.ORDER_MANAGEMENT,
          newspaperOrderErrors || new Error('No newspaper orders found'),
          'Failed to get newspaper orders',
          {
            orderId: order.id
          }
        );
        return;
      }

      const [failedToGetRate, rate] = await newspaperOrders[0].getRate();

      if (failedToGetRate) {
        logAndCaptureException(
          ColumnService.ORDER_MANAGEMENT,
          failedToGetRate,
          'Failed to get rate',
          {
            orderId: order.id,
            newspaperOrderId: newspaperOrders[0].id
          }
        );
        return;
      }
      return rate.data().rateType === RateType.flat.value;
    },
    dependencies: [order.id]
  });

  const invoiceSnap = useFirestoreDocumentListener(invoiceFromOrder?.ref);
  const invoice = invoiceSnap
    ? new InvoiceModel<OrderInvoice>(getFirebaseContext(), {
        snap: invoiceSnap
      })
    : undefined;
  const invoiceIsLoading = invoiceIsGetting || invoiceIsUpdating;

  const handleSendToEditFlow = async (): Promise<ResponseOrError<void>> => {
    const {
      response,
      error: duplicateError
    } = await order.generateNewVersionOfOrderItems();
    if (duplicateError) {
      return wrapError(duplicateError);
    }

    const { version: newVersion } = response;
    const reauthorizationParams = anonymousUserAuthInfo
      ? `&accessCode=${
          anonymousUserAuthInfo.accessCode
        }&email=${encodeURIComponent(anonymousUserAuthInfo.authEmail)}`
      : '';
    dispatch(
      push(
        `/${PRODUCT_TO_NAME[
          order.modelData.product
        ].plural.toLowerCase()}/edit/${
          order.id
        }?version=${newVersion}${reauthorizationParams}`
      )
    );
    return wrapSuccess(undefined);
  };

  const handleCancelOrder = async (
    cancellationReason: string
  ): Promise<ResponseOrError<void>> => {
    setInvoiceIsUpdating(true);
    const { error: cancelError } = await sendCancelOrderRequest(
      cancellationReason,
      order,
      anonymousUserAuthInfo
    );
    await invoice?.refreshData();
    setInvoiceIsUpdating(false);
    if (cancelError) {
      return wrapError(new Error('Failed to cancel order'));
    }
    return wrapSuccess(undefined);
  };

  // We only allow edits on a flat rate marked as paid order because the there will be no additional charges
  const disableEditButton = !!invoice?.isPaidDirectToPubisher() && !isFlatRate;

  return (
    <>
      {disableEditButton && (
        <Tooltip
          helpText="This order cannot be edited. The invoice was marked as paid and the rate type is not a flat rate."
          position="bottom"
        >
          <ExclamationCircleIcon className="h-5 w-5" />
        </Tooltip>
      )}
      <EditOrderButton
        editData={editData}
        onEditOrder={handleSendToEditFlow}
        disabled={disableEditButton}
      />
      <CancelOrderButton
        order={order}
        orderDetail={orderDetail}
        onCancelOrder={handleCancelOrder}
        isAnonymousUser={!!anonymousUserAuthInfo}
      />
      <MoreActions
        order={order}
        invoice={invoice}
        invoiceIsLoading={invoiceIsLoading}
      />
    </>
  );
}
