import { Product } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getModelFromSnapshot } from 'lib/model';
import {
  BriefcaseIcon,
  CheckCircleIcon,
  CogIcon,
  HomeIcon,
  HomeModernIcon,
  SpeakerWaveIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';
import { PetsOutlined } from '@material-ui/icons';
import { ClassifiedFilingTypeNames } from 'lib/types/classified';
import { ObituaryFilingTypeNames } from 'lib/types/obituary';
import { CategoryChoiceOption } from 'lib/types/ad';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectIsPublisher } from 'redux/auth';
import { exists } from 'lib/types';
import { getContextKey } from 'sagas/AuthSaga';
import { OrgContextState } from 'lib/types/organization';
import api from 'api';
import { safeGetOrThrow } from 'lib/safeWrappers';
import { getLogger } from 'utils/logger';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';

export type FilingTypeOption = {
  icon?: JSX.Element;
  title: string;
  description: string;
  id: CategoryChoiceOption;
};

export const CLASSIFIED_OPTIONS: FilingTypeOption[] = [
  {
    icon: <SpeakerWaveIcon className="h-6 w-6 flex-shrink-0" />,
    title: ClassifiedFilingTypeNames.AnnouncementsEvents,
    id: ClassifiedFilingTypeNames.AnnouncementsEvents,
    description: 'Business announcements, press release, community events'
  },
  {
    icon: <UserGroupIcon className="h-6 w-6 flex-shrink-0" />,
    title: ClassifiedFilingTypeNames.CommunityEngagementLetters,
    id: ClassifiedFilingTypeNames.CommunityEngagementLetters,
    description: 'Letter to the editor, advocacy'
  },
  {
    icon: <HomeIcon className="h-6 w-6 flex-shrink-0" />,
    title: ClassifiedFilingTypeNames.ForSale,
    id: ClassifiedFilingTypeNames.ForSale,
    description: 'Furniture, vehicles, motorcycles'
  },
  {
    icon: <HomeModernIcon className="h-6 w-6 flex-shrink-0" />,
    title: ClassifiedFilingTypeNames.HousingProperty,
    id: ClassifiedFilingTypeNames.HousingProperty,
    description: 'Residential, commercial, rentals'
  },
  {
    icon: <CheckCircleIcon className="h-6 w-6 flex-shrink-0" />,
    title: ClassifiedFilingTypeNames.Milestones,
    id: ClassifiedFilingTypeNames.Milestones,
    description: 'Engagement, wedding, retirement, baby announcement'
  },
  {
    icon: <PetsOutlined className="h-6 w-6 flex-shrink-0" />,
    title: ClassifiedFilingTypeNames.Pets,
    id: ClassifiedFilingTypeNames.Pets,
    description: 'For sale, re-homing'
  },
  {
    icon: <CogIcon className="h-6 w-6 flex-shrink-0" />,
    title: ClassifiedFilingTypeNames.Services,
    id: ClassifiedFilingTypeNames.Services,
    description: 'Small business, real estate, automotive'
  }
];

export const OBIT_OPTIONS: FilingTypeOption[] = [
  {
    title: ObituaryFilingTypeNames.Obituary,
    id: ObituaryFilingTypeNames.Obituary,
    description:
      'Long-form text with a photo and a short biography. Standard obituaries are usually more expensive but let you include more information about the deceased and their life.'
  },
  {
    title: ObituaryFilingTypeNames.DeathNotice,
    id: ObituaryFilingTypeNames.DeathNotice,
    description:
      'A short notice that includes the name, date of death, and age of the deceased. Death notices are typically less expensive than obituaries but do not include a photo or biography.'
  }
];

export function useAvailableFilingTypes({ product }: { product: Product }) {
  const isPublisher = useAppSelector(selectIsPublisher);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  if (getBooleanFlag(LaunchDarklyFlags.ENABLE_JOB_CATEGORY_IN_PLACEMENT_FLOW)) {
    if (
      !CLASSIFIED_OPTIONS.map(option => option.id).includes(
        ClassifiedFilingTypeNames.Jobs
      )
    ) {
      CLASSIFIED_OPTIONS.push({
        icon: <BriefcaseIcon className="h-6 w-6 flex-shrink-0" />,
        title: ClassifiedFilingTypeNames.Jobs,
        id: ClassifiedFilingTypeNames.Jobs,
        description: 'Job listings'
      });
    }
  }
  const defaultOptions =
    product === Product.Classified ? CLASSIFIED_OPTIONS : OBIT_OPTIONS;

  const getOrganizationContext = async () => {
    if (exists(activeOrganization) && isPublisher) return activeOrganization;
    const subdomain = getContextKey();
    if (!subdomain) return null;
    let subdomainContext: OrgContextState;
    try {
      subdomainContext = ((await api.safeGetWithParams<'organizations/:subdomain/context'>(
        `organizations/${subdomain}/context`
      )) as unknown) as OrgContextState;
    } catch (err) {
      getLogger().warn('Unable to look up subdomain');
      return null;
    }
    if (!subdomainContext) return null;
    const [fetchError, organization] = await safeGetOrThrow(
      getFirebaseContext().organizationsRef().doc(subdomainContext.id)
    );
    if (fetchError) {
      getLogger().warn(
        'Unable to retrieve organization from database from subdomain context',
        {
          orgid: subdomainContext.id
        }
      );
      return null;
    }
    return organization;
  };

  const { value: options, isLoading } = useAsyncEffect({
    fetchData: async () => {
      const orgContext = await getOrganizationContext();
      if (!orgContext) return defaultOptions;
      const organizationModel = getModelFromSnapshot(
        OrganizationModel,
        getFirebaseContext(),
        orgContext
      );
      const [
        fetchError,
        publisherSpecificFilingTypes
      ] = await organizationModel.fetchFilingTypesForProductMedium({
        product
      });
      if (fetchError || !publisherSpecificFilingTypes) return defaultOptions;
      const enabledFilingTypes: FilingTypeOption[] = [];
      for (const defaultFilingType of defaultOptions) {
        const customType = publisherSpecificFilingTypes.find(
          filingType =>
            filingType.modelData.label === defaultFilingType.title &&
            filingType.isVisibleToUser(isPublisher)
        );
        if (customType) {
          enabledFilingTypes.push({
            ...defaultFilingType,
            title: customType.modelData.customLabel || defaultFilingType.title,
            description:
              customType.modelData.customDescription ||
              defaultFilingType.description
          });
        }
      }
      return enabledFilingTypes;
    },
    dependencies: []
  });
  return { options, loadingFilingTypes: isLoading };
}
