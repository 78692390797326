/**
 * NOTE: This file uses a series of TypeScript tricks to extract some type
 * information into a form that is available at runtime. By doing this we
 * ensure that the helpers in this file stay useful and correct as we update
 * our types.
 */
import { replaceNullWithUndefined } from 'lib/helpers';
import { ENotice, ENoticeDraft } from 'lib/types';
import { EPlacement } from 'redux/placement';

/**
 * All keys that are in both EPlacement and ENotice
 */
type KeysOf_EPlacement_ENotice = Extract<keyof EPlacement, keyof ENotice>;

const PLACEMENT_NOTICE_OBJ: Required<
  Record<KeysOf_EPlacement_ENotice, undefined>
> = {
  filer: undefined,
  publicationDates: undefined,
  adTemplate: undefined,
  rate: undefined,
  newspaper: undefined,
  noticeType: undefined,
  previousNoticeType: undefined,
  columns: undefined,
  confirmedHtml: undefined,
  unusedConfirmedHtml: undefined,
  confirmedCrop: undefined,
  displayParams: undefined,
  displayUrl: undefined,
  invoiceMailings: undefined,
  filedBy: undefined,
  createdBy: undefined,
  processedDisplay: undefined,
  squashable: undefined,
  dynamicHeaders: undefined,
  dynamicFooter: undefined,
  footerFormatString: undefined,
  pdfStoragePath: undefined,
  jpgStoragePath: undefined,
  jpgURL: undefined,
  referenceId: undefined,
  proofStoragePath: undefined,
  customAffidavit: undefined,
  defaultRateOverride: undefined,
  userId: undefined,
  formattingError: undefined,
  postWithoutFormatting: undefined,
  requiresFormatting: undefined,
  designNotes: undefined,
  continueWithLargeFile: undefined,
  requireEmailAffidavit: undefined,
  mailAffidavitsOutsideColumn: undefined,
  invoiceRecipient: undefined,
  fixedPrice: undefined,
  owner: undefined,
  madlibData: undefined,
  text: undefined,
  headerText: undefined,
  anonymousFilerId: undefined,
  placedViaEmailAutomation: undefined
};

/**
 * A type-safe list of all ENotice fields which are in EPlacement.
 */
const PLACEMENT_NOTICE_FIELDS = Object.keys(
  PLACEMENT_NOTICE_OBJ
) as Array<KeysOf_EPlacement_ENotice>;

/**
 * All keys that are in both EPlacement and ENoticeDraft
 */
type KeysOf_EPlacement_ENoticeDraft = Extract<
  keyof EPlacement,
  keyof ENoticeDraft
>;

const PLACEMENT_NOTICE_DRAFT_OBJ: Required<
  Record<KeysOf_EPlacement_ENoticeDraft, undefined>
> = {
  ...PLACEMENT_NOTICE_OBJ,
  original: undefined,
  unusedDisplay: undefined,
  anonymousFilerId: undefined,
  madlibData: undefined,
  publicationDatesUpdated: undefined
};

/**
 * A type-safe list of all ENoticeDraft fields which are in EPlacement.
 */
const PLACEMENT_NOTICE_DRAFT_FIELDS = Object.keys(
  PLACEMENT_NOTICE_DRAFT_OBJ
) as Array<KeysOf_EPlacement_ENoticeDraft>;

const pickAllowedFields = <T extends object, U extends keyof T>(
  data: T,
  allowedFields: Array<U>
): Pick<T, U> => {
  const res: Partial<T> = {};

  const keys = Object.keys(data) as Array<keyof T>;
  for (const k of keys) {
    // This 'as any' is required because of how 'includes()` is typed. We know
    // that 'k' will be a string so this is safe.
    if (allowedFields.includes(k as any)) {
      res[k] = data[k];
    }
  }

  return res as Pick<T, U>;
};

/**
 * Get the fields from an existing notice which were set during the placement flow.
 */
export const getPlacementFlowFieldsFromNotice = (
  notice: ENotice
): Pick<ENotice, KeysOf_EPlacement_ENotice> => {
  return pickAllowedFields(notice, PLACEMENT_NOTICE_FIELDS);
};

/**
 * Extract ENoticeDraft fields from EPlacement.
 */
export const getNoticeDraftFieldsFromPlacement = (
  placement: EPlacement
): Pick<EPlacement, KeysOf_EPlacement_ENoticeDraft> => {
  return pickAllowedFields(placement, PLACEMENT_NOTICE_DRAFT_FIELDS);
};

/**
 * Extract a partial ENotice object from Placement state.
 */
export const getPartialNoticeFromPlacement = (
  placement: EPlacement
): Partial<ENotice> => {
  return replaceNullWithUndefined(
    pickAllowedFields(placement, PLACEMENT_NOTICE_FIELDS)
  );
};
