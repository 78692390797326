import { ColumnSelect } from 'lib/components/ColumnSelect';
import ColumnDatePicker from 'components/ColumnDatePicker';
import { Label } from 'lib/components/Label';
import React from 'react';
import { ScheduleChangesTableFilters } from './helpers';

type ScheduleChangesFilterDialogProps = {
  onUpdate: (updatedFilter: ScheduleChangesTableFilters) => void;
  value: ScheduleChangesTableFilters;
};

export function ScheduleChangesFilterDialog({
  onUpdate,
  value
}: ScheduleChangesFilterDialogProps) {
  return (
    <div className="flex flex-col gap-3">
      <p className="uppercase text-column-gray-300 text-xs font-bold border-b border-column-gray-100 pb-3">
        Filter by
      </p>
      <ColumnSelect
        id="schedule-changes-pub-date-filter-type"
        labelText="Publication date"
        onChange={newValue => {
          if (newValue !== 'specific') {
            onUpdate({ ...value, publicationDate: { type: newValue } });
          } else {
            onUpdate({
              ...value,
              publicationDate: { type: 'specific', date: new Date() }
            });
          }
        }}
        options={[
          { label: 'All publication dates', value: 'all' },
          { label: 'Future publication dates', value: 'all-future' },
          { label: 'Past publication dates', value: 'all-past' },
          { label: 'Specific date', value: 'specific' }
        ]}
        value={value.publicationDate.type}
      />
      {value.publicationDate.type === 'specific' && (
        <div className="my-2">
          <Label id="schedule-changes-pub-date-filter-date">
            Choose a date
            <ColumnDatePicker
              className="p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary"
              format="MMM dd, yyyy"
              momentFormat="MMM DD, YYYY"
              placeholderText="Filter Date"
              value={value.publicationDate.date}
              onChange={newDate =>
                onUpdate({
                  ...value,
                  publicationDate: {
                    type: 'specific',
                    date: newDate ?? new Date()
                  }
                })
              }
            />
          </Label>
        </div>
      )}
      <ColumnSelect
        id="schedule-changes-deadline-date-filter-type"
        labelText="Deadline date"
        onChange={newValue => {
          if (newValue === 'all') {
            onUpdate({ ...value, deadlineDate: { type: 'all' } });
          } else {
            onUpdate({
              ...value,
              deadlineDate: { type: 'specific', date: new Date() }
            });
          }
        }}
        options={[
          { label: 'All', value: 'all' },
          { label: 'Specific date', value: 'specific' }
        ]}
        value={value.deadlineDate.type}
      />
      {value.deadlineDate.type === 'specific' && (
        <div className="my-2">
          <Label id="schedule-changes-deadline-date-filter-date">
            Choose a date
            <ColumnDatePicker
              className="p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary"
              format="MMM dd, yyyy"
              momentFormat="MMM DD, YYYY"
              placeholderText="Filter Date"
              value={value.deadlineDate.date}
              onChange={newDate =>
                onUpdate({
                  ...value,
                  deadlineDate: {
                    type: 'specific',
                    date: newDate ?? new Date()
                  }
                })
              }
            />
          </Label>
        </div>
      )}
      <ColumnSelect
        id="schedule-changes-publishing-on-filter"
        labelText="Publishing?"
        onChange={newValue => {
          if (newValue === 'any') {
            onUpdate({ ...value, publish: null });
          } else if (newValue === 'yes') {
            onUpdate({ ...value, publish: true });
          } else if (newValue === 'no') {
            onUpdate({ ...value, publish: false });
          }
        }}
        options={[
          { label: 'Any', value: 'any' },
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' }
        ]}
        value={value.publish ? 'yes' : value.publish === false ? 'no' : 'any'}
      />
    </div>
  );
}
