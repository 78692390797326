import { Product } from 'lib/enums';
import { Ad } from 'lib/types/ad';
import { Layout } from 'lib/types/layout';
import MinimalMce from '../MinimalMce';
import { AdEditorData } from '../FormattedEditorWrapper';
import { ORDER_PLACEMENT_COLORS } from '../ColorSwatchPanel';

const titlePlaceholderByProduct: Record<Product, string> = {
  [Product.Classified]: 'Title',
  [Product.Notice]: 'Title',
  [Product.Obituary]: 'Name of loved one'
};

type TitleEditorProps<T extends Ad> = {
  adData: Partial<T>;
  onChange: (data: AdEditorData) => void;
  disableEditing: boolean;
  product: Product;
  layout: Layout;
};

function TitleEditor<T extends Ad>({
  adData,
  onChange,
  disableEditing,
  product,
  layout
}: TitleEditorProps<T>) {
  const colorOptionNamesByHexCode = Object.fromEntries(
    ORDER_PLACEMENT_COLORS.map(color => [color, color])
  );

  return (
    <MinimalMce
      toolbarItems={[['undo', 'redo', 'forecolor']]}
      onChange={value => {
        onChange({
          title: value
        });
      }}
      inline
      value={adData.title ?? ''}
      id="ad-title-editor"
      disabled={disableEditing}
      placeholder={
        layout.titlePlaceholder || titlePlaceholderByProduct[product]
      }
      required
      colorOptionNamesByHexCode={colorOptionNamesByHexCode}
      forbidCustomColors
    />
  );
}

export default TitleEditor;
