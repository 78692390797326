import LoadingState from 'components/LoadingState';
import { OrderModel } from 'lib/model/objects/orderModel';
import { ColumnService } from 'lib/services/directory';
import { centsToDollarsString, stripHtmlTags } from 'lib/helpers';
import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import AdDetailsCard from 'routes/notice/AdDetailsCard';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher, selectUser } from 'redux/auth';
import { isClassifiedModel } from 'lib/model/objects/adModel';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { OrderDetailModel } from 'lib/model/objects/orderDetailModel';
import { AdDetailHeader } from './AdDetailHeader';
import { PublicationDetails } from './PublicationDetails';
import { useOrderEditableData } from '../place/hooks/useOrderEditableData';
import { ClassifiedCustomerDetail } from './ClassifiedCustomerDetail';

type ClassifiedDetailProps = {
  order: OrderModel;
  orderDetail: OrderDetailModel;
  newspaperOrders: NewspaperOrderModel[];
  anonymousUserAuthInfo: { authEmail: string; accessCode: string } | undefined;
};

export function ClassifiedDetail({
  order,
  orderDetail,
  newspaperOrders,
  anonymousUserAuthInfo
}: ClassifiedDetailProps) {
  const userSnap = useAppSelector(selectUser);
  const isPublisher = useAppSelector(selectIsPublisher);

  const {
    value: classified,
    isLoading: loadingClassified
  } = useSafeAsyncEffect({
    fetchData: async () => order.getAdByVersion(),
    errorConfig: {
      service: ColumnService.ORDER_MANAGEMENT,
      message: 'Error fetching classified',
      tags: { orderId: order.id }
    },
    dependencies: [order.id, order.modelData.activeVersion]
  });

  const editData = useOrderEditableData({
    userSnap,
    order,
    newspaperOrders: newspaperOrders.map(no => no.modelData)
  });

  if (loadingClassified || !classified || !isClassifiedModel(classified)) {
    return (
      <LoadingState
        context={{
          service: ColumnService.ORDER_MANAGEMENT,
          location: 'Classified Detail',
          tags: { orderId: order.id, adDetail: 'true' }
        }}
      />
    );
  }

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12 flex justify-between lg:py-4">
        <AdDetailHeader
          title={stripHtmlTags(classified.modelData.title ?? '')}
          order={order}
          orderDetail={orderDetail}
          anonymousUserAuthInfo={anonymousUserAuthInfo}
          editData={editData}
        />
      </div>

      <div className="col-span-12 lg:col-span-8 space-y-3">
        <AdDetailsCard
          id="publication-details"
          header={{
            title: 'Publication Details'
          }}
        >
          {newspaperOrders.map(newspaperOrder => (
            <PublicationDetails
              key={newspaperOrder.id}
              newspaperOrder={newspaperOrder}
              ad={classified}
              editData={editData?.[newspaperOrder.id]}
            />
          ))}
        </AdDetailsCard>
        {isPublisher && (
          <AdDetailsCard
            id="customer-details"
            header={{ title: 'Customer Details' }}
          >
            <ClassifiedCustomerDetail order={order} />
          </AdDetailsCard>
        )}
      </div>

      <div className="col-span-12 lg:col-span-4">
        <AdDetailsCard id="order-details" header={{ title: 'Order Details' }}>
          <div className="text-column-gray-500 pb-1">Order no</div>
          <div className="text-column-gray-400">{order.id}</div>
          <div className="text-column-gray-500 pt-4 pb-1">Price</div>
          <div className="text-column-gray-400">
            {orderDetail.modelData.pricing?.totalInCents
              ? `$${centsToDollarsString(
                  orderDetail.modelData.pricing?.totalInCents
                )}`
              : ''}
          </div>
        </AdDetailsCard>
      </div>
    </div>
  );
}
