import { CompactSwitchControlledCard } from 'lib/components/Card/CompactSwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { isDefined } from 'lib/helpers';
import { AdRate, ERate } from 'lib/types';
import { OrderRate } from 'lib/types/rates';

type ColorFeesProps = {
  onChange: (newRate: AdRate | ERate | OrderRate) => void;
  value: OrderRate;
};

function ColorFees({ value, onChange }: ColorFeesProps) {
  return (
    <CompactSwitchControlledCard
      labelProps={{
        label: 'Enable color?',
        description: 'Show selected color options in the placement flow.',
        value: isDefined(value.colorFees?.flatFee),
        onChange: newValue => {
          onChange({
            ...value,
            colorFees: newValue ? { flatFee: 0 } : {}
          });
        }
      }}
      background="white"
    >
      <GridInput fullWidth>
        <CurrencyTextField
          id="color-flat-fee-input"
          labelText="Flat fee for all color placements"
          initialValue={value.colorFees?.flatFee}
          onChange={newValue => {
            const { flatFee, ...updatedFees } = value.colorFees ?? {};

            onChange({
              ...value,
              colorFees: { ...updatedFees, flatFee: newValue || 0 }
            });
          }}
        />
      </GridInput>
      <GridInput fullWidth>
        <CompactSwitchControlledCard
          labelProps={{
            label: 'Enable text color?',
            description:
              'Allow the user to choose from a set of text colors in the placement flow.',
            value: isDefined(value.colorFees?.textColorFee),
            onChange: newValue => {
              const { textColorFee, ...updatedFees } = value.colorFees ?? {};

              onChange({
                ...value,
                colorFees: {
                  ...updatedFees,
                  ...(newValue ? { textColorFee: 0 } : {})
                }
              });
            }
          }}
        >
          <CurrencyTextField
            initialValue={value.colorFees?.textColorFee ?? 0}
            onChange={newValue => {
              const { textColorFee, ...updatedFees } = value.colorFees ?? {};

              onChange({
                ...value,
                colorFees: {
                  ...updatedFees,
                  textColorFee: newValue ?? 0
                }
              });
            }}
            id="text-color-fee-input"
            labelText="Fee"
          />
        </CompactSwitchControlledCard>
      </GridInput>
      <GridInput fullWidth>
        <CompactSwitchControlledCard
          labelProps={{
            label: 'Enable border color?',
            description:
              'Allow the user to choose from a set of border colors in the placement flow.',
            value: isDefined(value.colorFees?.borderColorFee),
            onChange: newValue => {
              const { borderColorFee, ...updatedFees } = value.colorFees ?? {};

              onChange({
                ...value,
                colorFees: {
                  ...updatedFees,
                  ...(newValue ? { borderColorFee: 0 } : {})
                }
              });
            }
          }}
        >
          <CurrencyTextField
            initialValue={value.colorFees?.borderColorFee ?? 0}
            onChange={newValue => {
              const { borderColorFee, ...updatedFees } = value.colorFees ?? {};

              onChange({
                ...value,
                colorFees: {
                  ...updatedFees,
                  borderColorFee: newValue ?? 0
                }
              });
            }}
            id="border-color-fee-input"
            labelText="Fee"
          />
        </CompactSwitchControlledCard>
      </GridInput>
      <GridInput fullWidth>
        <CompactSwitchControlledCard
          labelProps={{
            label: 'Enable background color?',
            description:
              'Allow the user to choose from a set of background colors in the placement flow.',
            value: isDefined(value.colorFees?.backgroundColorFee),
            onChange: newValue => {
              const { backgroundColorFee, ...updatedFees } =
                value.colorFees ?? {};

              onChange({
                ...value,
                colorFees: {
                  ...updatedFees,
                  ...(newValue ? { backgroundColorFee: 0 } : {})
                }
              });
            }
          }}
        >
          <CurrencyTextField
            initialValue={value.colorFees?.backgroundColorFee ?? 0}
            onChange={newValue => {
              const { backgroundColorFee, ...updatedFees } =
                value.colorFees ?? {};

              onChange({
                ...value,
                colorFees: {
                  ...updatedFees,
                  backgroundColorFee: newValue ?? 0
                }
              });
            }}
            id="background-color-fee-input"
            labelText="Fee"
          />
        </CompactSwitchControlledCard>
      </GridInput>
    </CompactSwitchControlledCard>
  );
}

export default ColorFees;
